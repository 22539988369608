import React, { useState } from "react";
import {useDispatch, useSelector} from "react-redux";
import {NavLink, useLocation, useNavigate} from 'react-router-dom';
import {setLocation} from "../../ducks/navigation";
import styles from './SidebarItem.module.scss';
import Icon from "../ui/Icon";
import { getPageSlug } from "../../utils/utils";

const resolveLinkPath = (childTo, parentTo) => `${parentTo}${childTo}`;

const SidebarItemHeader = props => {
    const { item } = props;
    const { label, Icon: fontIcon, to: headerToPath, children, parentLabel, disabled, roles, level } = item;
    const locationExpand = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const currentUser = useSelector((state) => state.user.currentUser);
    // Calculates left padding pixel count based on nested level value
    const paddedValue = (level) => (level ? (level * 15) + 12 : 12);

    const lastPathComponent = getPageSlug(locationExpand.pathname);
    const itemTo = item.to.split('/');
    const itemToLastComponent = itemTo[itemTo.length - 1];
    const isHeaderActive = lastPathComponent === itemToLastComponent;

    const [expanded, setExpand] = useState(
        locationExpand.pathname.includes(headerToPath)
    )

    const onExpandChange = (e, level) => {
        e.preventDefault();
        if (level !== 0) {
            setExpand(expanded => !expanded);
        }
    }

    const onNavHeaderClick = (e, disabled, to, parentLabel, label) => {
        e.preventDefault();
        e.stopPropagation();
        if(!disabled){
            dispatch(setLocation({path: to, name: parentLabel, page: label}))
            navigate(to);
        }
    };

    const onNavItemClick = (e, disabled, to, propsTo, parentLabel, label) => {
        e.preventDefault();
        e.stopPropagation();
        if(!disabled){
            dispatch(setLocation({path: resolveLinkPath(to, propsTo), name: parentLabel, page: label}))
            navigate(resolveLinkPath(to, propsTo));
        }
    };

    if(roles && !roles.includes(currentUser?.roleId)) {
        return <></>
    }

    const getNavHeaderContainerClass = () => {
        if (level === 0) {
            return styles.oxfordBlueNavHeaderLink;
        }
        if (level === 1) {
            return styles.navHeaderLinkFirstLevel;
        }
        if (level > 1) {
            return isHeaderActive ? styles.greyNavHeaderLink : styles.oxfordBlueNavHeaderLink;
        }
    }

    const getNavHeaderClass = () => {
        if (level === 1){
            return item.lastElement ? styles.navItemFirstLevelLastElement : styles.navItemFirstLevel;
        }
    }

    const getNavItemClass = (isActive, el) => {
        const activeStyleNoChildrenWithBorder = `${styles.firstLevelNavItem} ${styles.firstLevelChild}`;
        const activeStyleNoChildrenWithBorderLastElement = `${styles.firstLevelNavItem} ${styles.firstLevelChild} ${styles.lastElement}`;

        if(el.level === 1){
            if(el.lastElement) {
                return isActive ? activeStyleNoChildrenWithBorderLastElement : styles.navItemFirstLevelLastElement;
            } else {
                return isActive ? activeStyleNoChildrenWithBorder : styles.navItemFirstLevel;
            }
        } else {
            return isActive ? styles.activeNavItemNoChildren : null
        }
    }

    // Conditionally applies the class to the navHeader and navHeaderParent components based on nested level position
    const navHeaderContainerComputedClass = getNavHeaderContainerClass();
    const navHeaderComputedClass = getNavHeaderClass();

    return (
        <>
            <div className={`${styles.navHeaderLink} ${navHeaderContainerComputedClass} ${ expanded ? styles.navHeaderExpanded : '' }`}>
                <NavLink
                    className={`${styles.navItem} ${navHeaderComputedClass}`}
                    to={item.to}
                    onClick={(e) => onExpandChange(e, level)}
                    style={{paddingLeft: `${paddedValue(level)}px`}}
                >
                        <Icon icon={fontIcon} size={18} className="me-2" />
                        <span
                            className={styles.navLabel}
                            onClick = {(e) => onNavHeaderClick(e, disabled, item.to, parentLabel, label)}
                        >
                            {label}
                        </span>
                </NavLink>
            </div>

            { expanded &&
                <div className={styles.navChildrenBlock}>
                {
                    children.map((item, index) => {
                        const key = `${item.label.replace(' ', '-').toLowerCase()}-${index}`;
                        const { label, Icon: fontIcon, children, disabled, roles, level } = item;
                        const lastPathComponent = getPageSlug(locationExpand.pathname);
                        const isChildActive = lastPathComponent === item.to.replace('/', '');

                        // Conditionally applies the class to the navHeader component based on nested level position
                        const navItemComputedClass = getNavItemClass(isChildActive, item);

                        if(roles && !roles.includes(currentUser?.roleId)) {
                            return <></>
                        }

                        if(children) {
                            return (
                                <div key={key}>
                                    <SidebarItemHeader
                                        item={{
                                            ...item,
                                            parentLabel: item.parentLabel ?? props.item.parentLabel,
                                            to: resolveLinkPath(item.to, props.item.to)
                                        }}
                                    />
                                </div>
                            );
                        }

                        return (
                            <NavLink
                                key={key}
                                to={resolveLinkPath(item.to, props.item.to)}
                                className={`${styles.navItem} ${navItemComputedClass}`}
                                onClick = {(e) => onNavItemClick(e, disabled, item.to, props.item.to, parentLabel, label)}
                                style={disabled ? { opacity: 0.5, cursor: 'default', paddingLeft: `${paddedValue(level)}px` } : { paddingLeft: `${paddedValue(level)}px` }}
                            >
                                <Icon icon={fontIcon} size={18} className="me-2" />
                                <span className={styles.navLabel}>{label}</span>
                            </NavLink>
                        );
                    })
                }
                </div>
            }

        </>
    );
}

export default SidebarItemHeader;
