import { NavLink, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Col, Nav } from 'react-bootstrap';
import withAuth from '../../../components/hoc/withAuth';
import { RiMapPinUserFill, RiUserSearchFill } from 'react-icons/ri';
import { BiChip } from 'react-icons/bi';
import { MdHealthAndSafety } from 'react-icons/md';
import { HiCurrencyDollar } from 'react-icons/hi';
import { LuAsterisk } from 'react-icons/lu';
import { setLocation } from '../../../ducks/navigation';
import ActionBar from '../../../components/ui/ActionBar';
import CohortSelector from './CohortSelector';
import styles from '../../admin/Admin.module.scss';

const PopulationCharacterization = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const Section = ({ page, path, icon }) => {
        const Icon = icon;

        return (
            <NavLink
                to={path}
                className={`${styles.pageLink} flex-grow-1 d-flex flex-column align-items-center justify-content-center rounded-2 py-5 px-3 mb-4`}
                onClick={(e) => {
                    e.preventDefault();
                    dispatch(
                        setLocation({
                            path: `/member-analytics/${path}}`,
                            name: 'Member Analytics',
                            page,
                        })
                    );
                    navigate(path);
                }}>
                <Icon className={`${styles.pageLinkIcon} mb-2`} />
                <h4 className={`${styles.pageLinkText} fw-bold mb-0 ms-3`}>
                    {page}
                </h4>
            </NavLink>
        );
    };

    return (
        <div className="h-100 d-flex flex-column">
            <ActionBar>
                <CohortSelector />
            </ActionBar>
            <div className="flex-grow-1">
                <Nav className={`${styles.pageLinks} row p-0`}>
                    <Col md={4}>
                        <Section
                            path="core-demographics"
                            page="Core Demographics"
                            icon={RiUserSearchFill}
                        />
                    </Col>
                    <Col md={4}>
                        <Section
                            path="compare-census"
                            page="Compare Census Data"
                            icon={RiUserSearchFill}
                        />
                    </Col>
                    <Col md={4}>
                        <Section
                            path="geographic-dispersion"
                            page="Geographic Dispersion"
                            icon={RiMapPinUserFill}
                        />
                    </Col>
                    <Col md={4}>
                        <Section
                            path="technology"
                            page="Technology Literacy"
                            icon={BiChip}
                        />
                    </Col>
                    <Col md={4}>
                        <Section
                            path="health-access"
                            page="Health Access"
                            icon={MdHealthAndSafety}
                        />
                    </Col>
                    <Col md={4}>
                        <Section
                            path="health-access-drill-down"
                            page="Health Access Drill Down"
                            icon={MdHealthAndSafety}
                        />
                    </Col>
                    <Col md={4}>
                        <Section
                            path="financial-index"
                            page="Financial Index"
                            icon={HiCurrencyDollar}
                        />
                    </Col>
                    <Col md={4}>
                        <Section
                            path="other-insights"
                            page="Other Insights"
                            icon={LuAsterisk}
                        />
                    </Col>
                </Nav>
            </div>
        </div>
    );
};

export default withAuth(PopulationCharacterization);
