import api from '../../services/api/reportService';
import keyMirror from '../../utils/keyMirror';
import { POWER_BI_REPORTS } from '../../constants'

export const actionTypes = keyMirror(
    {
        GET_EMBEDED_URLS: null,
        SET_REPORT_CARD_REPORT: null,
        SET_STAR_EXPLORER_REPORT: null,
        SET_POPULATION_CHARACTERIZATION_REPORT: null,
        SET_RISK_MANAGEMENT_REPORT: null
    },
    'Report'
);

// Action Creators
export const getPowerBiEmbededUrls = () => dispatch => {
    api.getPowerBiEmbededUrls().then(response => {
        const { data } = response;
        dispatch({
            type: actionTypes.GET_EMBEDED_URLS,
            payload: data,
        });
    });
}
export const setPowerBiReport = (reportData) => dispatch => {
    if (reportData.reportName === POWER_BI_REPORTS.STAR_REPORT_CARD) {
        dispatch({
            type: actionTypes.SET_REPORT_CARD_REPORT,
            payload: { ...reportData.data },
        });
    }
    else if (reportData.reportName === POWER_BI_REPORTS.STARS_DATA_EXPLORER) {
        dispatch({
            type: actionTypes.SET_STAR_EXPLORER_REPORT,
            payload: { ...reportData.data },
        });
    }
    else if (reportData.reportName === POWER_BI_REPORTS.POPULATION_CHARECTERIZATIONS) {
        dispatch({
            type: actionTypes.SET_POPULATION_CHARACTERIZATION_REPORT,
            payload: reportData.data,
        });
    }
}

// Reducer
export const initialState = {
    reportCardSlicerState: null,
    starExplorerSlicerState: null,
    populationCharacterizationSlicerState: null,
    riskManagementSlicerState: null,
    accessToken: '',
    home: {},
    stateOverview: {},
    nationalDashboard: {},
    stateDetail: {},
    reportCard: {},
    multiFacility: {},
    timely: {},
    patientExp: {},
    safety: {},
    readmission: {},
    mortality: {},
    coreDemographic: {},
    technology: {},
    compareCensus: {},
    geographicDispersion: {},
    healthAccess: {},
    healthAccessDrilldown: {},
    geographicDrilldown: {},
    technologyDrillDown: {},
};

export default function reportReducer(state = initialState, { type, payload }) {
    switch (type) {
        case actionTypes.GET_EMBEDED_URLS: {
            return {
                ...state,
                accessToken: payload.accessToken,
                home: {
                    ...payload.value.find(item => {
                        return (item.name === 'home')
                    })
                },
                nationalDashboard: {
                    ...payload.value.find(item => {
                        return (item.name === 'nationalDashboard')
                    })
                },
                stateOverview: {
                    ...payload.value.find(item => {
                        return (item.name === 'stateOverview')
                    })
                },
                stateDetail: {
                    ...payload.value.find(item => {
                        return (item.name === 'stateDetail')
                    })
                },
                reportCard: {
                    ...payload.value.find(item => {
                        return (item.name === 'reportCard')
                    })
                },
                multiFacility: {
                    ...payload.value.find(item => {
                        return (item.name === 'multiFacility')
                    })
                },
                timely: {
                    ...payload.value.find(item => {
                        return (item.name === 'timely')
                    })
                },
                patientExp: {
                    ...payload.value.find(item => {
                        return (item.name === 'patientExp')
                    })
                },
                safety: {
                    ...payload.value.find(item => {
                        return (item.name === 'safety')
                    })
                },
                readmission: {
                    ...payload.value.find(item => {
                        return (item.name === 'readmission')
                    })
                },
                mortality: {
                    ...payload.value.find(item => {
                        return (item.name === 'mortality')
                    })
                },
                coreDemographic: {
                    ...payload.value.find(item => {
                        return (item.name === 'coreDemographic')
                    })
                },
                technology: {
                    ...payload.value.find(item => {
                        return (item.name === 'technology')
                    })
                },
                compareCensus: {
                    ...payload.value.find(item => {
                        return (item.name === 'compareCensus')
                    })
                },
                geographicDispersion: {
                    ...payload.value.find(item => {
                        return (item.name === 'geographicDispersion')
                    })
                },
                healthAccess: {
                    ...payload.value.find(item => {
                        return (item.name === 'healthAccess')
                    })
                },
                healthAccessDrilldown: {
                    ...payload.value.find(item => {
                        return (item.name === 'healthAccessDrilldown')
                    })
                },
                geographicDrilldown: {
                    ...payload.value.find(item => {
                        return (item.name === 'geographicDrilldown')
                    })
                },
                technologyDrillDown: {
                    ...payload.value.find(item => {
                        return (item.name === 'technologyDrillDown')
                    })
                },
            };
        }

        case actionTypes.SET_REPORT_CARD_REPORT: {
            return {
                ...state,
                reportCardSlicerState: payload
            };
        }

        case actionTypes.SET_STAR_EXPLORER_REPORT: {
            return {
                ...state,
                starExplorerSlicerState: payload
            };
        }

        case actionTypes.SET_POPULATION_CHARACTERIZATION_REPORT: {
            return {
                ...state,
                populationCharacterizationSlicerState: payload
            };
        }

        case actionTypes.SET_RISK_MANAGEMENT_REPORT: {
            return {
                ...state,
                riskManagementSlicerState: payload
            };
        }

        default:
            return state;
    }
};
