import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Alert, Button, Col, ProgressBar, Row } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { FaSave, FaTimes } from 'react-icons/fa';
import { IoMdArrowRoundBack } from 'react-icons/io';
import Placeholder from '../../components/ui/Placeholder';
import styles from '../../styles/AddCohort.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { getCurrentUser } from '../../ducks/user';
import { useMsal } from '@azure/msal-react';
import { checkWhiteSpace, isArrayUniqueValues, isObjectEmpty } from '../../utils/utils';
import withAuth from '../../components/hoc/withAuth';
import api from '../../services/api/memberAnalyticsService';
import Loader from '../../components/ui/Loader';
import { toast } from "react-toastify";
import messages from "../../utils/helper/messages";
import ActionBar from "../../components/ui/ActionBar";
import FileUploader from "../../components/ui/FileUploader"
import { BsFillExclamationTriangleFill } from "react-icons/bs";
import LoadingButton from "../../components/ui/LoadingButton";


const AddCohort = () => {
    const [isLoading, setIsLoading] = useState(false);
    // used to keep our own state (e.g., for resetting after submission) since dropZone acceptedFiles is reaadonly
    const [isUploading, setIsUploading] = useState(false);
    const [uploadFiles, setUploadFiles] = useState([]);
    const [cohortInfo, setCohortInfo] = useState({});
    const [progressInfos, setProgressInfos] = useState({ val: [] });
    const progressInfosRef = useRef(null);

    const { instance, inProgress } = useMsal();
    const currentUser = useSelector((state) => state.user.currentUser);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        async function fetchData() {
            if (!currentUser) {
                setIsLoading(true);

                await dispatch(getCurrentUser(instance));

                setIsLoading(false);
            }
        }

        fetchData();
    }, [dispatch, inProgress, instance, currentUser]);


    const onDropAccepted = (acceptFiles) => {
        for (const currentFile of acceptFiles) {
            // prevent duplicate files
            const found = uploadFiles.some(
                (file) =>
                    file.name === currentFile.name &&
                    file.size === currentFile.size
            );
            if (!found) {
                setUploadFiles((files) => [...files, currentFile]);
                setCohortInfo((info) => {
                    return { ...info, ...{ [currentFile.name]: '' } };
                })
            }
        }
    }


    const files = uploadFiles.map((file, index) => (
        <li key={file.path} className={styles.fileItem}>
            <div>
                <span className={styles.fileName}>{file.path}</span>-
                <span className={styles.fileSize}>{(file.size / 1000).toFixed(1)} KB</span>
                {progressInfos && progressInfos.val.length > 0 &&
                    <div className={styles.fileProgress} key={`progress-${index}`}>
                        <ProgressBar now={progressInfos.val[index].percentage} />
                    </div>
                }
            </div>
            <div className="d-flex">
                <Form.Control
                    type="text"
                    placeholder="Cohort Name"
                    className={styles.fileInput}
                    required
                    value={file.name in cohortInfo ? cohortInfo[file.name] : ''}
                    onChange={e => {
                        setCohortInfo({ ...cohortInfo, ...{ [file.name]: e.target.value } })
                    }}
                />
                <Button variant="light" size="sm" className={`${styles.fileRemove} ms-2`} onClick={() => removeFile(file)}>
                    <FaTimes />
                </Button>
            </div>
        </li>
    ))

    const removeFile = (file) => {
        const updatedFilesArr = uploadFiles.filter(item => item.name !== file.name);
        const updatedCohortArr = Object.entries(cohortInfo).filter(item => item[0] !== file.name);
        const updatedCohortInfo = Object.fromEntries(updatedCohortArr);

        setUploadFiles(updatedFilesArr);
        setCohortInfo({ ...updatedCohortInfo });
    }

    const onUpload = async (event) => {
        setIsUploading(true);
        console.log('onUpload')

        event.preventDefault();
        console.log(uploadFiles);

        const renamedAcceptedFiles = uploadFiles.map((file) => {
            let cohortName = cohortInfo[file.name];
            let fileObj = new File([file], `${currentUser.companyId}_${cohortName}_${file.name}`, { type: file.type });

            // set properties to assign to blob metadata
            fileObj.companyId = currentUser.companyId;
            fileObj.uploaderUserId = currentUser.id;
            fileObj.cohortName = cohortName;
            fileObj.originalFileName = file.name;

            return fileObj;
        });

        let _progressInfos = renamedAcceptedFiles.map(file => ({ percentage: 0, fileName: file.name }));
        progressInfosRef.current = {
            val: _progressInfos,
        }

        for (let [index, currentFile] of renamedAcceptedFiles.entries()) {

            let _progressInfos = [...progressInfosRef.current.val];

            try {
                console.log('uploading', currentFile.name)

                await api.uploadFile(currentFile, (event) => {
                    _progressInfos[index].percentage = Math.round(
                        (100 * event.loaded) / event.total
                    );
                    setProgressInfos({ val: _progressInfos });

                    _progressInfos[index].percentage === 100 && toast(`${currentFile.name} ${messages.success.cohortUpload}`, { type: "success", toastId: `cohort-add-success-${index}` });
                });

            } catch (error) {
                toast(`${messages.error.cohortUpload} ${currentFile.name} ${error}.`, { type: "error", toastId: `cohort-add-error-${index}` });
                _progressInfos[index].percentage = 0;
                setProgressInfos({ val: _progressInfos });
            }

            setIsUploading(false);
        }

        // reset drop zone data and cohortInfo
        setCohortInfo({});
        setUploadFiles([]);
        setProgressInfos({ val: [] });
    }

    const isValidCohortInfo = () => {
        if (isObjectEmpty(cohortInfo)) {
            return false;
        }

        const valArr = Object.values(cohortInfo);
        const hasEmptyString = valArr.includes('');
        const hasWhiteSpace = valArr.some(checkWhiteSpace);

        return isArrayUniqueValues(valArr) && !hasEmptyString && !hasWhiteSpace;
    }

    if (isLoading) {
        return (
            <Loader />
        );
    }

    return (
        <div className="d-flex flex-column h-100">
            <ActionBar title="Add Cohort">
                <div>
                    <Button type="button" variant="light" className="me-2" onClick={() => navigate(-1)}>
                        <IoMdArrowRoundBack className="me-1" />Back
                    </Button>
                    <LoadingButton type="submit" disabled={!files.length || !isValidCohortInfo() || isUploading} loading={isUploading} onClick={onUpload}>
                        <FaSave size={14} className="me-1" />Save
                    </LoadingButton>
                </div>
            </ActionBar>
            <Row className="flex-grow-1">
                <Col md={4}>
                    <FileUploader onDropAccepted={file => onDropAccepted(file)} />
                </Col>
                <Col md={8}>
                    {
                        files.length ?
                            <>
                                <h4>Upload Manager</h4>
                                <ul className={styles.filesList}>{files}</ul>
                                {
                                    !isValidCohortInfo() &&
                                    <Alert
                                        variant="warning"
                                        className="mt-2 py-2 lh-sm"
                                    >
                                        <div className="d-flex align-items-center">
                                            <BsFillExclamationTriangleFill className="me-2" />
                                            {messages.error.cohortNameValidation}
                                        </div>
                                    </Alert>
                                }
                            </> : <Placeholder title="Upload files on left to proceed" customStyle="bg-white border" />
                    }
                </Col>
            </Row>
        </div>
    );
};

export default withAuth(AddCohort);
