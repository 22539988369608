import React, { useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ProgressBar } from 'react-bootstrap';
import { getCurrentUser } from '../../ducks/user';
import { useMsal } from '@azure/msal-react';
import withAuth from '../../components/hoc/withAuth';
import Loader from '../../components/ui/Loader';
import { getCohorts } from '../../ducks/cohort';
import { AppLoadContext } from '../../components/ui/AppLoadContext';
import Form from 'react-bootstrap/Form';
import { useNavigate, useParams } from 'react-router-dom';
import api from '../../services/api/memberAnalyticsService';
import { toast } from 'react-toastify';
import messages from '../../utils/helper/messages';
import Button from 'react-bootstrap/Button';
import styles from '../../styles/AddCohort.module.scss';
import { optionValues } from '../../utils/utils';
import { COHORT_TRAINING_FLAG } from '../../constants';

const allowedExtensions = [
    'csv',
    'vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'vnd.ms-excel',
];
const EditCohort = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [cohortData, setCohortData] = useState(null);
    const [error, setError] = useState("");
    const [progress, setProgress] = useState(0);

    const [file, setFile] = useState("");
    const isAppLoaded = useContext(AppLoadContext);
    const navigate = useNavigate();

    const { cohortId } = useParams();

    const { instance, inProgress } = useMsal();
    const currentUser = useSelector((state) => state.user.currentUser);
    const cohort = useSelector((state) => state.cohort.cohorts);

    const dispatch = useDispatch();
    const trainingFlagOptions = optionValues(COHORT_TRAINING_FLAG);
    const progressRef = useRef(null);

    useEffect(() => {
        if (cohort !== null) {
            let num = +cohortId; // to convert type of string to number
            setCohortData(cohort.find(a => a.id === num));
            console.log(cohortData);
        }
    }, [cohortId, cohort]);

    useEffect(() => {
        if (isAppLoaded) {
            if (!currentUser) {
                return;
            }
            dispatch(getCohorts(currentUser?.companyId));
        }
        // Safe to add dispatch to the dependencies array
    }, [dispatch, isAppLoaded, currentUser]);

    useEffect(() => {
        async function fetchData() {
            if (!currentUser) {
                setIsLoading(true);

                await dispatch(getCurrentUser(instance));

                setIsLoading(false);
            }
        }

        fetchData();
    }, [dispatch, inProgress, instance, currentUser]);


    const Cancel = () => {
        navigate("/admin/cohort-management");
    }
    const inputChanged = (e) => {

        setError("");

        // Check if user has entered the file
        if (e.target.files.length) {
            const inputFile = e.target.files[0];

            // Check the file extensions, if it not
            // included in the allowed extensions
            // we show the error
            const fileExtension = inputFile?.type.split("/")[1];

            if (!allowedExtensions.includes(fileExtension)) {
                setError("Please input a csv file");
                return;
            }

            // If input type is correct set the state
            setFile(inputFile);
        }
    }

    const Submit = () => {
        if (!file) return setError("Enter a file");

        let fileObj = new File([file], `${currentUser.companyId}_${cohortData.name}_${file.name}`, { type: file.type });

        // set properties to assign to blob metadata

        fileObj.cohortId = cohortId;
        fileObj.cohortName = cohortData.name;
        fileObj.companyId = currentUser.companyId;
        fileObj.uploaderUserId = currentUser.id;
        fileObj.originalFileName = file.name;
        fileObj.training = cohortData.training;

        try {

            api.uploadFileEditCohort(fileObj, (event) => {
                progressRef.current = Math.round(
                    (100 * event.loaded) / event.total
                );
                setProgress(progressRef.current);

                if (progressRef.current === 100) {
                    toast(`${file.name} ${messages.success.cohortUpload}`, { type: "success", toastId: "cohort-update-success" });
                    navigate("/admin/cohort-management");
                }
            })
        } catch (error) {
            toast(`${messages.error.cohortUpload} ${file[0].name} ${error}.`, { type: "error", toastId: "cohort-update-error" });
        }
    }

    if (isLoading) {
        return (
            <Loader />
        );
    }

    return (
        cohortData === null || cohortData === undefined ?
            <div className="text-center text-secondary py-2">No data</div>
            :
            <div>
                <Form.Label>Cohort name</Form.Label>
                <Form.Control
                    type={'text'}
                    aria-describedby="inputGroupPrepend"
                    name={'name'}
                    readOnly
                    value={cohortData.name}
                />
                <div className="my-3">
                    <Form.Label>Training Cohort</Form.Label>
                    <Form.Select
                        name="training"
                        value={cohortData.training}
                        disabled
                    >
                        {
                            trainingFlagOptions?.map((optionInfo) => {
                                return (
                                    <option
                                        key={optionInfo.id}
                                        value={optionInfo.id}>
                                        {optionInfo.value}
                                    </option>
                                );
                            })
                        }
                    </Form.Select>
                </div>
                <div className="my-3">
                    <Form.Label>File</Form.Label>
                    <Form.Control
                        type={'file'}
                        accept=".csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                        aria-describedby="inputGroupPrepend"
                        onChange={inputChanged}
                        name={'File'}
                        isInvalid={error}
                    />
                    <Form.Control.Feedback type="invalid">
                        {error}
                    </Form.Control.Feedback>
                </div>
                <div className="mt-4">
                    <Button type="cancel" className="me-3" onClick={Cancel}>Cancel</Button>
                    <Button type="submit" disabled={!file || error !== ""} onClick={Submit}>Submit</Button>
                </div>
                <>
                    {progress > 0 &&
                        <div>
                            <span className={styles.fileName}>{file.name}</span>-
                            <span className={styles.fileSize}>{(file.size / 1000).toFixed(1)} KB</span>
                            <div className={styles.fileProgress} >
                                <ProgressBar now={progress} />
                            </div>
                        </div>
                    }
                </>
            </div>
    );
};

export default withAuth(EditCohort);
