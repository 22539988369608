import {useNavigate} from "react-router-dom";
import {Button, Form} from "react-bootstrap";
import {FaFileExport} from "react-icons/fa";
import {IoMdArrowRoundBack} from "react-icons/io";
import withAuth from "../../../components/hoc/withAuth";
import ActionBar from "../../../components/ui/ActionBar";
import DataGrid from "../../../components/DataGrid";

// mock data, remove after backend integration
const alertMessages = [
    {
        id: 1,
        memberId: 2,
        description: 'Lorem ipsum dolor',
        priority: 'Urgent',
        dateTime: '07/05/2023 5:32:12PM'
    },
    {
        id: 2,
        memberId: 3,
        description: 'Lorem ipsum dolor',
        priority: 'High Priority',
        dateTime: '07/15/2023 5:32:12PM'
    },
];

const RiskMonitoringAlert = () => {
    const navigate = useNavigate();

    const columns = [
        {
            name: 'check',
            header: ' ',
            size: 36,
            minSize: 36,
            cell: ({ row }) => (
                <Form.Check
                    variant="secondary"
                    id={row.original.id}
                    size="sm"
                    className="me-1 mb-1 d-flex align-items-center"
                />
            )
        },
        {
            name: 'id',
            header: 'ID',
            size: 60,
            cell: ({ row }) => (<a href="#">{row.id}</a>),
            accessorKey: 'id',
            enableColumnFilter: false,
        },
        {
            name: 'memberId',
            header: 'Member ID',
            size: 100,
            accessorKey: 'memberId',
        },
        {
            name: 'description',
            header: 'Description',
            accessorKey: 'description',
            enableColumnFilter: false,
        },
        {
            name: 'priority',
            header: 'Priority',
            accessorKey: 'priority',
            size: 130,
        },
        {
            name: 'dateTime',
            header: 'Date Time',
            accessorKey: 'dateTime',
            size: 170,
            enableColumnFilter: false,
        }
    ];

    const defaultSort = [{
        id: 'dateTime',
        desc: true
    }];

    const handleExport = () => {
        // alerts export code
    }

    return (
        <div className="h-100 d-flex flex-column">
            <ActionBar back={true}>
                <Button type="button" className="flex-shrink-0" variant="light" onClick={() => navigate('/member-analytics/retention-risk/risk-monitoring')}>
                    <IoMdArrowRoundBack className="me-1" />Back
                </Button>
                <Button type="button" className="me-2 fw-bold flex-shrink-0">
                    <FaFileExport size={14} className="me-2" onClick={handleExport} />Export
                </Button>
            </ActionBar>
            <div className="flex-grow-1">
                <DataGrid
                    columns={columns}
                    data={alertMessages}
                    sort={defaultSort}
                />
            </div>
        </div>
    );
}

export default withAuth(RiskMonitoringAlert);
