import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import ActionBar from "../../../components/ui/ActionBar";
import Icon from "../../../components/ui/Icon";
import withAuth from "../../../components/hoc/withAuth";
import OverflowText from "../../../components/ui/OverflowTooltip";
import EditMemberModal from "./EditMemberModal";
import BackButton from "../../../components/ui/BackButton";
import NewCohortModal from "../../cohort-management/NewCohortModal";
import { MemberData } from './MemberData';
import { formatPhoneNumber, toDate } from '../../../utils/utils';
import { FaPencilAlt } from "react-icons/fa";
import { updateMember } from "../../../ducks/cohort";
import { toast } from "react-toastify";
import messages from "../../../utils/helper/messages";

export const MemberList = () => {
    const { cohortId } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [confirmModal, setConfirmModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [currentMember, setCurrentMember] = useState(null);
    const [addModal, setAddModal] = useState(false);

    const columns = [
        {
            id: 'firstName',
            header: 'Name',
            cell: ({ row, cell }) => {
                let name = `${row.original.firstName} ${row.original.lastName}`;
                return (<OverflowText id={cell.id}>{name}</OverflowText>)
            },
            maxSize: 100,
        },
        {
            id: 'addressLine1',
            header: 'Address',
            cell: ({ row, cell }) => {
                let address = `${row.original.addressLine1}${row.original.addressLine2 && ' ' + row.original.addressLine2}, ${row.original.state}, ${row.original.postalCode}`;
                return (<OverflowText id={cell.id}>{address}</OverflowText>)
            },
            maxSize: 200,
        },
        {
            id: 'birthday',
            header: 'Birthday',
            accessorKey: 'birthday',
            accessorFn: row => `${toDate(row.enrollDate)}`,
            minSize: 100,
        },
        {
            id: 'email',
            header: 'Email',
            accessorKey: 'email',
            cell: ({ row, cell }) => (<OverflowText id={cell.id}>{row.original.email}</OverflowText>),
            maxSize: 200,
        },
        {
            id: 'phone',
            header: 'Phone',
            accessorKey: 'phone',
            cell: ({ row }) => (<div className="text-nowrap">{formatPhoneNumber(row.original.phone)}</div>),
            size: 120,
        },
        {
            id: 'enrollDate',
            header: 'Enroll Date',
            accessorKey: 'enrollDate',
            accessorFn: row => `${toDate(row.enrollDate)}`,
            minSize: 100,
        },
        {
            id: 'terminationDate',
            header: 'Termination Date',
            accessorKey: 'terminationDate',
            accessorFn: row => `${toDate(row.terminationDate)}`,
            minSize: 100,
        },
        {
            id: 'contract',
            header: 'Contract',
            accessorKey: 'contract',
        },
        {
            id: 'pbpId',
            header: 'PBP ID',
            accessorKey: 'pbpId',
        },
        {
            id: 'salesChannelName',
            header: 'Sales Channel Name',
            accessorKey: 'salesChannelName',
        },
        {
            id: 'writingAgentId',
            header: 'Agent Id',
            accessorKey: 'writingAgentId',
        },
        {
            id: 'writingAgentName',
            header: 'Agent Name',
            accessorKey: 'writingAgentName',
            cell: ({ row }) => (row.original.writingAgentName),
            maxSize: 170,
        },
        {
            id: 'customerInteractionsCountPhone',
            header: 'Interactions Count Phone',
            accessorKey: 'customerInteractionsCountPhone',
        },
        {
            id: 'customerInteractionsCountEmail',
            header: 'InteractionsCountEmail',
            accessorKey: 'customerInteractionsCountEmail',
        },
        {
            id: 'customerInteractionsCountWeb',
            header: 'Interactions Count Web',
            accessorKey: 'customerInteractionsCountWeb',
        },
        {
            id: 'customerInteractionsCountBroker',
            header: 'Interactions Count Broker',
            accessorKey: 'customerInteractionsCountBroker',
        },
        {
            id: 'customerInteractionsCountOther',
            header: 'Interactions Count Other',
            accessorKey: 'customerInteractionsCountOther',
        },
        {
            id: 'status',
            header: 'Status',
            accessorKey: 'status',
        },
        {
            name: 'actions',
            header: 'Actions',
            cell: ({ row }) => (
                <Button variant="light" size="sm" className="me-1 my-1" onClick={() => editMember(row.original)}>
                    <FaPencilAlt />
                </Button>
            )
        },
    ];

    const editMember = (member) => {
        setConfirmModal(true);
        setCurrentMember({
            ...member,
            birthday: toDate(member.birthday),
            enrollDate: toDate(member.enrollDate),
            terminationDate: toDate(member.terminationDate)
        });
    }

    const handleConfirm = async (formData) => {
        setIsLoading(true);
        editMemberData({
            ...formData, memberId: currentMember.memberId,
            cohortId: currentMember.cohortId
        });
        setIsLoading(false);
        handleConfirmClose();
    }

    const editMemberData = (formData) => {
        dispatch(updateMember(formData));
        toast(`${messages.success.updateCohortMember}`, { type: "success", toastId: "update-cohort-member-success" });
    }

    const handleConfirmClose = () => {
        setConfirmModal(false);
    }

    const handleAddModalClose = () => {
        setAddModal(false);
    }

    return (
        <>
            <ActionBar back={true}>
                <BackButton onBack={() => navigate('/admin/cohort-management')} />
                <div>
                    <Button type="button" variant="secondary" className="me-2"
                            onClick={() => navigate(`/admin/cohort-management/edit/${cohortId}`)}>
                        Re-upload
                    </Button>
                    <Button type="button" variant="primary" className="me-2"
                            onClick={() => setAddModal(true)}>
                        <Icon icon="new" size={14} className="me-2" />New
                    </Button>
                </div>
            </ActionBar>

            <MemberData cohortId={cohortId} columns={columns} />

            <EditMemberModal
                loading={isLoading}
                show={confirmModal}
                member={currentMember}
                variant="danger"
                onConfirm={handleConfirm}
                onCancel={handleConfirmClose}
                onHideCallback={handleConfirmClose}
            />

            <NewCohortModal
                show={addModal}
                onCancel={handleAddModalClose}
                onHideCallback={handleAddModalClose}
            />
        </>
    );
}

export default withAuth(MemberList);
