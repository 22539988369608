import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Container, Dropdown, Form, InputGroup, Navbar, Modal } from "react-bootstrap";
import DropdownMenu from "react-bootstrap/DropdownMenu";
import { setLocation } from "../../ducks/navigation";
import DropdownItem from "react-bootstrap/DropdownItem";
import { b2cPolicies, loginRequest } from "../../msalAuthConfig";

import logo from "../../assets/images/logo.svg";
import logoAlt from "../../assets/images/pharo-logo.svg";
import styles from "./NavigationBar.module.scss";
import {
    FaInfo,
    FaPhoneAlt,
    FaQuestionCircle,
    FaSearch,
    FaSignOutAlt,
    FaUser,
    FaUserAlt
} from "react-icons/fa";
import pageString from "../../utils/helper/pageString";
import React, { useEffect, useState } from "react";
import * as SessionHandler from "../../services/sessionHandler";
import { UserProfileModal } from "./UserProfileModal";
import useRouteMatch from "../../hooks/useRouteMatch";
import menuData from "./menuData";
import { MdEmail } from "react-icons/md";
import { getPageSlug } from "../../utils/utils";

const PharoLogo = () => (
    <div className={styles.logoWrap}>
        <img src={logo} alt="Predict Health" className={styles.logo} />
    </div>
)

export const NavigationBar = () => {

    const { instance } = useMsal();
    const dispatch = useDispatch();
    const [showUserProfileModal, setShowUserProfileModal] = useState(false);
    const [showSupportModal, setShowSupportModal] = useState(false);
    const routeMatch = useRouteMatch(pageString.routePatterns);

    const handelChangePassword = async () => {
        await instance.loginRedirect(b2cPolicies.authorities.forgotPassword);
    }

    const handleSearch = (event) => {
        event.preventDefault();
        // search functionality
    }

    const currentUser = useSelector((state) => state.user.currentUser);

    const detectNavigationSection = (path) => {
        if (!path) return;
        let { pathnameBase, pathname } = path;
        let slug = getPageSlug(pathname);
        let endPath = `/${slug}`;
        let section = '';

        switch (pathnameBase.replace('/', '')) {
            case pageString.route.home:
                section = pageString.navigation.home
                break
            case pageString.route.adminCenter:
                section = pageString.navigation.adminCenter
                break
            case pageString.route.qualityManagement:
                section = pageString.navigation.qualityManagement
                break
            case pageString.route.memberAnalytics:
                section = pageString.navigation.memberAnalytics
                break
            default:
                break
        }

        let sectionObj = menuData.find(item => item.label === section);
        if (sectionObj) {
            let pageName = sectionObj.to === endPath ? sectionObj.label : getPageName(sectionObj, endPath);

            dispatch(setLocation({ path: pathnameBase, name: section, page: pageName }));
        }
    }

    const getPageName = (obj, path) => {
        const data = obj.children;
        for (let i = 0; i < data.length; i++) {
            const item = data[i];
            if (item.to === path) {
                return item.label;
            } else if (item.children) {
                const result = getPageName(item, path);
                if (result) {
                    return result;
                }
            }
        }
        return null;
    }

    const handleSupportModalClose = () => setShowSupportModal(false);
    const handleSupportModalShow = () => setShowSupportModal(true);

    useEffect(() => {
        detectNavigationSection(routeMatch);
    }, [location.path]);

    /**
     * Most applications will need to conditionally render certain components based on whether a user is signed in or not.
     * msal-react provides 2 easy ways to do this. AuthenticatedTemplate and UnauthenticatedTemplate components will
     * only render their children if a user is authenticated or unauthenticated, respectively.
     */
    return (
        <>

            <AuthenticatedTemplate>
                <Navbar bg="white" variant="light" className={styles.header}>
                    <Container fluid={true} className="px-0">
                        <div className="d-flex align-items-center w-100">
                            <div className={`me-3 pe-2 border-2 border-light border-end ${styles.controlBar}`}>
                                <div className={`search-box mx-2 ${styles.searchBox}`}>
                                    <InputGroup>
                                        <Form.Control
                                            placeholder="Search"
                                            aria-label="Search"
                                            aria-describedby="basic-addon1"
                                        />
                                        <Button variant="light" id="button-addon1" onClick={handleSearch}>
                                            <FaSearch size={16} />
                                        </Button>
                                    </InputGroup>
                                </div>
                                <Button variant="light" className={`me-2 fw-semibold flex-shrink-0 ${styles.helpBox}`}>
                                    <FaQuestionCircle className={`${styles.helpBoxInner}`} />
                                </Button>
                                <Dropdown>
                                    <Dropdown.Toggle variant="secondary" id="dropdown-basic" className={styles.userInitials}>
                                        {
                                            currentUser ?
                                                currentUser.firstName.charAt(0).toUpperCase() + currentUser.lastName.charAt(0).toUpperCase()
                                                :
                                                <FaUserAlt size={16} />
                                        }
                                    </Dropdown.Toggle>
                                    <DropdownMenu align="end" className="border-light border-top-0 shadow-sm mt-2 pt-2 rounded-0 rounded-bottom">
                                        <DropdownItem
                                            onClick={() => setShowUserProfileModal(true)}>
                                            <FaUser className="small text-muted me-2" />View Profile
                                        </DropdownItem>
                                        <DropdownItem
                                            onClick={() => handelChangePassword()}>
                                            <FaUser className="small text-muted me-2" />Change Password
                                        </DropdownItem>
                                        <Dropdown.Item as="button"
                                            onClick={async () => {
                                                await SessionHandler.handleLogout();
                                            }}>
                                            <FaSignOutAlt className="small text-muted me-2" />Sign out
                                        </Dropdown.Item>
                                    </DropdownMenu>
                                </Dropdown>
                            </div>
                            <PharoLogo />
                        </div>

                        <UserProfileModal
                            showModal={showUserProfileModal}
                            selectedUser={currentUser}
                            onHideCallback={() => setShowUserProfileModal(false)}
                            isReadOnly={true}
                        />
                    </Container>
                </Navbar>
            </AuthenticatedTemplate>

            <UnauthenticatedTemplate>
                <Navbar bg="white" variant="light" className={`${styles.header} ${styles.login} px-xl-4`}>
                    <Container fluid={true} className="px-0 px-xl-3">
                        <a href="https://predicthealth.ai" target="_blank" rel="noreferrer" className={styles.logoWrap}>
                            <img src={logoAlt} alt="Predict Health" className={`${styles.logo} ${styles.alt}`} />
                        </a>
                        <div className="ms-auto">
                            <Button variant="secondary" className="me-3" drop="start" title="Sign In"
                                onClick={() => instance.loginRedirect(loginRequest)}>
                                Sign in
                            </Button>
                            <Button className={`${styles.btnSupport} rounded-circle p-0 border-0`}
                                onClick={handleSupportModalShow}>
                                <FaInfo />
                            </Button>
                        </div>
                    </Container>
                </Navbar>
                <Modal
                    show={showSupportModal}
                    onHide={handleSupportModalClose}>
                    <Modal.Header closeButton className="ps-lg-4">
                        <Modal.Title>Support</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="p-lg-4">
                        <h3 className="h5 mb-3">Support Options for PHARO platform:</h3>
                        <div className="d-flex">
                            <div className={`${styles.supportIcon} rounded-circle flex-shrink-0 me-3 d-flex align-items-center justify-content-center`}><MdEmail /></div>
                            <p><strong className={styles.supportTitle}>Email</strong><br />You can send us an email at <a
                                href="mailto:CustomerService@predicthealth.net" className={styles.supportLink}>CustomerService@predicthealth.net</a>. Please provide your contact information and a description of the problem or issue that you are encountering</p>
                        </div>
                        <div className="d-flex">
                            <div className={`${styles.supportIcon} ${styles.small} rounded-circle flex-shrink-0 me-3 d-flex align-items-center justify-content-center`}><FaPhoneAlt /></div>
                            <p><strong className={styles.supportTitle}>Telephone</strong><br />You can call our Customer Service line at <a
                                href="tel:8888024747" className={styles.supportLink}>888-802-4747</a>. Our Customer Service lines are open Monday through Friday between 9am and 5pm ET.</p>
                        </div>
                    </Modal.Body>
                </Modal>
            </UnauthenticatedTemplate>
        </>
    );
};
