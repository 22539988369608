import React, { useEffect, useState } from 'react';
import SharedForm from '../../components/ui/Form'
import { useNavigate, useParams } from "react-router-dom";
import {
    createCompany,
    updateCompany,
    updateCompanyParams,
    resetCompany,
    getCompanyById,
    getCompanyLogoById,
    getCompany
} from '../../ducks/company'
import { useSelector, useDispatch } from 'react-redux'
import withAuth from "../../components/hoc/withAuth";
import { toast } from 'react-toastify';
import messages from "../../utils/helper/messages";
import { isUserSysAdmin } from '../../utils/utils';

function AddCompany() {
    const colorOptions = [
        { id: 'oxfordBlue', label: 'Oxford Blue', value: '#0c153d' },
        { id: 'greyLight', label: 'Grey Light', value: '#f7f7f7' }
    ];

    const arr = [
        { key: 'name', labelName: 'Company Name', placeholderName: 'Company Name', type: 'text' },
        { key: 'address', labelName: 'Address', placeholderName: 'Address', type: 'text' },
        { key: 'city', labelName: 'City', placeholderName: 'City', type: 'text' },
        { key: 'state', labelName: 'State', placeholderName: 'State', type: 'text' },
        { key: 'portalCode', labelName: 'Portal Code', placeholderName: 'Portal Code', type: 'text' },
        { key: 'contactFirstName', labelName: 'Contact First Name', placeholderName: 'Contact First Name', type: 'text' },
        { key: 'contactLastName', labelName: 'Contact Last Name', placeholderName: 'Contact Last Name', type: 'text' },
        { key: 'contactEmail', labelName: 'Contact Email', placeholderName: 'Contact Email', type: 'email' },
        { key: 'contactPhone', labelName: 'Contact Phone', placeholderName: 'Contact Phone', type: 'phone' },
        { key: 'logo', labelName: 'Logo', placeholderName: 'Logo', type: 'file' },
        { key: 'logoBgColor', labelName: 'Logo Background', placeholderName: 'Select Logo Background', type: 'radio', optionValues: colorOptions }
    ];

    const { id } = useParams();
    const [isLoading, setIsLoading] = useState(false);
    const company = useSelector((state) => state.company);
    const currentUser = useSelector((state) => state.user.currentUser);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    useEffect(() => {
        if (id) {
            dispatch(getCompanyById(id));
            dispatch(getCompanyLogoById(id));
        }
        else {
            dispatch(resetCompany());
        }
        // Safe to add dispatch to the dependencies array
    }, [dispatch, id]);

    const Submit = async () => {
        setIsLoading(true);
        try {

            dispatch(id ? updateCompany(company.company) : createCompany(company.company));

            setIsLoading(false);
            toast(id ? `${messages.success.updateCompany}` : `${messages.success.createCompany}`, { type: "success", toastId: id ? "update-company-success" : "add-company-success" });

            isUserSysAdmin(currentUser) ? dispatch(getCompany()) : dispatch(getCompany(currentUser?.companyId));
            navigate("/admin/company-management");

        }
        catch (error) {
            setIsLoading(false);
            toast(`${messages.error.prefix} ${error}.`, { type: "error", toastId: id ? "update-company-error" : "add-company-error" });
            navigate("/admin/company-management");
        }
    }

    const Cancel = () => {
        navigate("/admin/company-management");
    }
    const inputChange = (name, value) => {
        dispatch(updateCompanyParams({ key: name, value: value }));
    }

    return (
        <div>
            <SharedForm array={arr} modelObj={company.company} onSubmit={Submit} onCancel={Cancel} onInputChanged={inputChange} loading={isLoading} />
        </div>
    )
}

export default withAuth(AddCompany);
