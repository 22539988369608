import React, { useEffect, useState, useContext } from 'react';
import { useSelector } from 'react-redux';
import { AppLoadContext } from '../../../../components/ui/AppLoadContext';
import withAuth from '../../../../components/hoc/withAuth';
import PopulationPowerBi from '../PopulationPowerBi';

const HealthAccessDrillDown = () => {
    const [reportConfig, setReportConfig] = useState(null);

    const report = useSelector((state) => state.report);

    const isAppLoaded = useContext(AppLoadContext);

    useEffect(() => {
        if (isAppLoaded && report.healthAccessDrilldown.embedId)
            setReportConfig({
                ...report.healthAccessDrilldown,
                accessToken: report.accessToken,
            });
    }, [report, isAppLoaded]);

    return (
        <PopulationPowerBi
            reportConfig={reportConfig}
            title="Health Access Drill Down"
        />
    );
};

export default withAuth(HealthAccessDrillDown);
