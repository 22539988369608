import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import {isPhoneNumberValid, isDevTestUserId, parsePhoneNumber} from "../../utils/utils";
import {USER_ROLE} from "../../constants";
import React, {useState} from "react";
import {acquireAccessToken} from "../../services/api/auth";
import {protectedResources} from "../../msalAuthConfig";
import {getCurrentUser, getUsers} from "../../ducks/user";
import {ApiInstance} from "../../views/admin/UserManagement";
import {useDispatch, useSelector} from "react-redux";
import {useMsal} from "@azure/msal-react";
import { toast } from 'react-toastify';
import LoadingButton from "./LoadingButton";
import messages from "../../utils/helper/messages";

export const UserProfileModal = ({showModal, selectedUser, inputChanged, onHideCallback, onFormSubmitCallback, isReadOnly}) => {
    const dispatch = useDispatch();
    const {instance} = useMsal();
    const [formValidated, setFormValidated] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const currentUser = useSelector((state) => state.user.currentUser);

    const onFormSubmit = async e => {
        const form = e.currentTarget;
        e.preventDefault();

        if (form.checkValidity() === false) {
            e.stopPropagation();
            setFormValidated(true);

            console.log('form invalid')

            return;
        }

        const formData = new FormData(e.target);
        const formDataObj = Object.fromEntries(formData.entries());

        // normalize phone number
        if (formDataObj.phone) {
            formDataObj.phone = parsePhoneNumber(formDataObj.phone);
        }

        try {
            setIsLoading(true);
            let accessToken = await acquireAccessToken(instance);
            ApiInstance.setAuth(accessToken);
            let result = await ApiInstance({
                method: 'PUT',
                url: `${protectedResources.apiUsers.endpoint}/update`,
                data: {
                    userData: {id: selectedUser.id, ...formDataObj}
                }
            });

            onFormSubmitCallback(result.data);
            setIsLoading(false);

            // update user initials if current user
            if(Number(selectedUser.id) === currentUser.id) {
                dispatch(getCurrentUser(instance));
                toast(messages.success.updateUser, { type: "success", closeButton: false, toastId: "update-user-success" });
            }

            // fetch and refresh users
            dispatch(getUsers(instance));

        } catch (error) {
            setIsLoading(false);
            toast(`${messages.error.prefix} ${error}.`, { type: "error", toastId: "update-user-error" });
        }
    };

    return (
        <Modal show={showModal} fullscreen={false} onHide={onHideCallback}>
            <Modal.Header closeButton>
                <Modal.Title>{isReadOnly ? 'User Profile' : 'Edit User'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form noValidate validated={formValidated} onSubmit={onFormSubmit}>
                    <Form.Group className="mb-3" controlId="formFirstName">
                        <Form.Label>First Name</Form.Label>
                        <Form.Control
                            name="firstName"
                            placeholder="Enter first name"
                            value={selectedUser?.firstName}
                            onChange={inputChanged}
                            required
                            disabled={isReadOnly}
                        />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formLastName">
                        <Form.Label>Last Name</Form.Label>
                        <Form.Control
                            name="lastName"
                            placeholder="Enter last name"
                            value={selectedUser?.lastName}
                            onChange={inputChanged}
                            required
                            disabled={isReadOnly}
                        />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formEmail">
                        <Form.Label>Email Address</Form.Label>
                        <Form.Control
                            name="email"
                            type="email"
                            placeholder="Enter email"
                            value={selectedUser?.email}
                            onChange={inputChanged}
                            required
                            disabled={isReadOnly}
                        />
                        {!isReadOnly ? <Form.Text className="text-muted">
                            Updating this value will require the user to re-auth.
                        </Form.Text> : null }
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formPhone">
                        <Form.Label>Phone Number</Form.Label>
                        <Form.Control
                            name="phone"
                            type="phone"
                            placeholder="Enter phone number"
                            value={selectedUser?.phone}
                            onChange={inputChanged}
                            isInvalid={!isDevTestUserId(selectedUser?.id) && !isPhoneNumberValid(selectedUser?.phone)}
                            required
                            disabled={isReadOnly}
                        />
                        <Form.Control.Feedback type="invalid">
                            It looks like the number entered isn't registered as a valid phone number.
                        </Form.Control.Feedback>
                        {!isReadOnly ? <Form.Text className="text-muted">
                            Updating this value will require the user to re-auth.
                        </Form.Text> : null}
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formRole">
                        <Form.Label>Role</Form.Label>
                        <Form.Select
                            name="roleId"
                            defaultValue={selectedUser?.roleId ? selectedUser.roleId : USER_ROLE.USER}
                            disabled={isReadOnly}
                        >
                            <option value={USER_ROLE.USER}>User</option>
                            <option value={USER_ROLE.CLIENT_ADMIN}>Client Admin</option>
                            <option value={USER_ROLE.SYSTEM_ADMIN}>System Admin</option>
                        </Form.Select>
                    </Form.Group>

                    {!isReadOnly ? <LoadingButton variant="primary" type="submit" disabled={isLoading} loading={isLoading}>Update</LoadingButton> : null}
                </Form>

            </Modal.Body>
        </Modal>
    );
};
