import React, { useContext, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import SharedForm from "../../../components/ui/Form";
import withAuth from "../../../components/hoc/withAuth";
import {
    createContent,
    getContentFile,
    updateContent,
} from "../../../ducks/content";
import messages from "../../../utils/helper/messages";
import { getFileExtension, optionValues } from "../../../utils/utils";
import {
    ALIGN,
    AUDIENCE_APPLICATION,
    AUDIENCE_SEGMENT,
    CMS_CONTENT_FORMAT,
    CMS_CONTENT_TYPE,
    MIME_TYPES,
    POSITIONS,
} from '../../../constants';
import { AppLoadContext } from "../../../components/ui/AppLoadContext";

const AddContentModal = ({ show, onCancel, content }) => {
    const id = content?.id;
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [contentDetails, setContentDetails] = useState(null);

    const isAppLoaded = useContext(AppLoadContext);
    const { contents } = useSelector((state) => state.content);
    const currentUser = useSelector((state) => state.user.currentUser);

    const contentTypeOptions = optionValues(CMS_CONTENT_TYPE);
    const segmentOptions = optionValues(AUDIENCE_SEGMENT);
    const applicationOptions = optionValues(AUDIENCE_APPLICATION);
    const fileFormatOptions = optionValues(CMS_CONTENT_FORMAT);

    const arr = [
        { key: 'type', labelName: 'Type', placeholderName: 'Type', type: 'options', optionValues: contentTypeOptions },
        { key: 'title', labelName: 'Title', placeholderName: 'Title', type: 'text' },
        { key: 'audienceSegment', labelName: 'Audience Segment', placeholderName: 'Audience Segment', type: 'options', optionValues: segmentOptions },
        { key: 'audienceApplication', labelName: 'Audience Application', placeholderName: 'Audience Application', type: 'options', optionValues: applicationOptions },
        { key: 'format', labelName: 'Format', placeholderName: 'File Format', type: 'options', optionValues: fileFormatOptions, disabled: true },
        {
            key: 'file', labelName: 'Content', placeholderName: 'Upload File', type: 'file',
            allowedTypes: [MIME_TYPES.PDF, MIME_TYPES.JPG, MIME_TYPES.HTML], fileNameKey: 'fileName'
        },
    ];

    const contentSchema = {
        id: -1,
        title: "",
        type: 'B',
        audienceSegment: 'A',
        audienceApplication: 'A',
        audienceUser: 'A',
        format: 'P',
        fileName: "",
        file: null,
        recordStatus: 'O',
    }

    const Submit = () => {
        setIsLoading(true);
        try {
            if (id) {
                dispatch(updateContent(contentDetails, () => {
                    toast(`${messages.success.updateContent}`, { type: "success", toastId: "update-content-success" });
                    setIsLoading(false);
                    onCancel();
                }));
            } else {
                dispatch(createContent(contentDetails, () => {
                    toast(`${messages.success.createContent}`, { type: "success", toastId: "create-content-success" });
                    setIsLoading(false);
                    onCancel();
                }));
            }
        } catch (error) {
            setIsLoading(false);
            toast(`${messages.error.prefix} ${error}.`, {
                type: "error",
                toastId: id ? "update-content-error" : "add-content-error"
            });
        }
    }

    const handleContentChange = (update) => {
        setContentDetails((details) => ({ ...details, ...update }))
    }

    const inputChange = (name, value) => {
        if (name === "file") {
            let fileExtension = getFileExtension(value.name).toUpperCase();
            fileExtension = (fileExtension === 'JPG' ? 'JPEG' : fileExtension);
            handleContentChange({
                fileName: value.name,
                file: value,
                format: Object.keys(CMS_CONTENT_FORMAT).find(
                    (type) => CMS_CONTENT_FORMAT[type] === fileExtension
                ),
            });
        } else {
            handleContentChange({ [name]: value });
        }
    }

    useEffect(() => {
        if (isAppLoaded) {
            if (id) {
                dispatch(getContentFile(id));
            } else {
                setContentDetails({
                    ...contentSchema,
                    createUserId: currentUser?.id,
                    updateUserId: currentUser?.id,
                });
            }
        }
    }, [isAppLoaded, dispatch, id]);

    useEffect(() => {
        if (contents?.length) {
            let content = contents.find((c) => c.id === parseInt(id));
            content && handleContentChange({ ...content });
        }
    }, [contents]);

    return (
        <Modal show={show} fullscreen={false} backdrop="static" onHide={onCancel} size="md">
            <Modal.Header closeButton>
                <Modal.Title>{ id ? 'Edit' : 'New' } Content</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="px-xl-3 py-xl-2">
                    <SharedForm
                        cols={12}
                        array={arr}
                        modelObj={contentDetails}
                        onSubmit={Submit}
                        onCancel={onCancel}
                        onInputChanged={inputChange}
                        loading={isLoading}
                        actionBarPosition={POSITIONS.TOP}
                        actionBarAlign={ALIGN.RIGHT}
                        submitButtonText={id ? 'Update' : 'Submit'}
                    />
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default withAuth(AddContentModal);
