
import { models } from 'powerbi-client';

export const USER_ROLE = {
    SYSTEM_ADMIN: 1,
    CLIENT_ADMIN: 2,
    USER: 3,
};

export const LOCAL_STORAGE_KEY = {
    CURRENT_USER: 'CURRENT_USER',
};

export const reportLayoutSettings = {
    layoutType: models.LayoutType.Master,
    customLayout: {
        pageSize: {
            type: models.PageSizeType.Custom,
        },
        displayOption: models.DisplayOption.FitToPage,
        pagesLayout: {
            ReportSection1: {
                defaultLayout: {
                    displayState: {
                        mode: models.VisualContainerDisplayMode.Hidden,
                    },
                },
                visualsLayout: {
                    VisualContainer1: {
                        x: 1,
                        y: 1,
                        z: 1,
                        displayState: {
                            mode: models.VisualContainerDisplayMode.Visible,
                        },
                    },
                    VisualContainer2: {
                        displayState: {
                            mode: models.VisualContainerDisplayMode.Visible,
                        },
                    },
                },
            },
        },
    },
};

export const POWER_BI_REPORTS = {
    STAR_REPORT_CARD: 'Star Report Card',
    STARS_DATA_EXPLORER: 'Stars Data Explorer',
    POPULATION_CHARECTERIZATIONS:'Population Characterizations'
};

export const USER_STATUS = {
    O: 'Operational',
    P: 'Permanent',
    A: 'Archived',
}

export const DEFAULT_PAGE = "Home";

export const COHORT_FILE_STATUS = {
    UPLOADED: 'Uploaded',
    VALIDATED: 'Validated',
    ENRICHED: 'Enriched',
    SCORED: 'Scored',
    ERROR: 'Error',
    ARCHIVED: 'Archived',
};

export const COHORT_TRAINING_FLAG = {
    Y: 'Yes',
    N: 'No',
};


export const AUDIENCE_USER_ROLE = {
    A: 'All', // default
    U: 'User',
    Z: 'Admin',
}

export const AUDIENCE_APPLICATION = {
    A: 'All', // default
    G: 'Advisory Group',
    M: 'Member Analytics',
    Q: 'Quality Management',
    Z: 'Admin Center',
}

export const AUDIENCE_SEGMENT= {
    A: 'All', // default
    H: 'Provider',
    P: 'Plan',
}

export const CMS_CONTENT_TYPE = {
    B: 'Blog',
    N: 'News',
    G: 'Graph of Month',
    A: 'Product Announcement',
    T: 'Product Tip',
}

export const CMS_CONTENT_FORMAT = {
    P: 'PDF',
    J: 'JPEG',
    H: 'HTML',
}

export const MIME_TYPES = {
    JPG: 'image/jpeg',
    JPEG: 'image/jpeg',
    PNG: 'image/png',
    SVG: 'image/svg+xml',
    GIF: 'image/gif',
    PDF: 'application/pdf',
    HTML: 'text/html',
    XLS: 'application/vnd.ms-excel',
    CSV: 'text/csv',
}

export const RECORD_STATUS = {
    O: 'Operational',
    A: 'Archived'
}

export const CONTENT_RECORD_STATUS = {
    Operational: 'O',
    Archived: 'A',
    Permanent: 'P'
}

export const POSITIONS = {
    TOP: 'top',
    BOTTOM: 'bottom',
}

export const ALIGN = {
    LEFT: 'start',
    RIGHT: 'end',
}
