import {
    Route, createBrowserRouter,
    createRoutesFromElements,
    RouterProvider
} from "react-router-dom";

// Home
import Layout from "../components/ui/Layout";
import Home from "./Home";

// Common Routes
import ErrorRedirect from "./ErrorRedirect";
import SessionExpired from "./SessionExpired";
import SessionLogout from "./SessionLogout";
import NotFound from "./NotFound";

// Admin Views
import Admin from "./admin/Admin";
import UserManagement from "./admin/UserManagement";
import Company from "./admin/Company";
import NewCompany from "./admin/AddCompany";
import ContentManagement from "./admin/cms/ContentManagement";
import ArchivedContent from "./admin/cms/ArchivedContent";
import EacParticipationResponses from "./admin/EacParticipationResponses";
import AddEACParticipationResponse from "./admin/AddEACParticipationResponse";

// Quality Management Views
import StarExplorerHome from "./starExplorer/home";
import StarExplorerNationalDashboard from "./starExplorer/nationalDashboard";
import QualityManagementHome from "./quality-management/Home";
import QualityManagementHospitalHome from "./quality-management/Hospital";
import CompanyReactTable from "./CompanyReactTable";
import StateOverview from "./starExplorer/stateOverview";
import StateDetail from "./starExplorer/stateDetail";
import ReportCard from "./starExplorer/reportCard";
import Mortality from "./starExplorer/mortality";
import Readmission from "./starExplorer/readmission";
import Safety from "./starExplorer/safety";
import PatientExp from "./starExplorer/patientExp";
import Timely from "./starExplorer/timely";
import MultiFacility from "./starExplorer/multiFacility";
import StarPlanner from "./starExplorer/StarPlanner";

// Retention Management Views
import MemberManagementHome from "./member-analytics/member-management/MemberManagementHome";
import CohortManagement from "./cohort-management/CohortManagement";
import AddCohort from "./cohort-management/AddCohort";
import EditCohort from "./cohort-management/EditCohort";
import MergeCohort from "./cohort-management/MergeCohort";
import ArchivedCohorts from "./cohort-management/ArchivedCohorts";
import MemberList from "./member-analytics/member-management/MemberList";
import CohortUploadOverview from "./cohort-management/CohortUploadOverview";
import PopulationCharacterization from "./member-analytics/population-characterization/PopulationCharacterization";
import CoreDemographics from "./member-analytics/population-characterization/CoreDemographics";
import GeographicDispersion from "./member-analytics/population-characterization/GeographicDispersion";
import TechnologyLiteracy from "./member-analytics/population-characterization/TechnologyLiteracy";
import HealthAccess from "./member-analytics/population-characterization/HealthAccess";
import FinancialIndex from "./member-analytics/population-characterization/FinancialIndex";
import OtherInsights from "./member-analytics/population-characterization/OtherInsights";
import CompareCensus from "./member-analytics/population-characterization/CompareCensus";
import PopulationDrillDown from "./member-analytics/population-characterization/drilldown/PopulationDrillDown";
import HealthAccessDrillDown from "./member-analytics/population-characterization/drilldown/HealthAccessDrillDown";
import RetentionRisk from "./member-analytics/retention-risk/RetentionRisk";
import RiskIdentification from "./member-analytics/retention-risk/RiskIdentification";
import RiskMonitoring from "./member-analytics/retention-risk/RiskMonitoring";
import RiskMonitoringAlert from "./member-analytics/retention-risk/RiskMonitoringAlert";
import RiskExploration from "./member-analytics/risk-exploration/RiskExploration";
import RiskCoreDemographics from "./member-analytics/risk-exploration/CoreDemographics";
import RiskGeographicDispersion from "./member-analytics/risk-exploration/GeographicDispersion";
import RiskTechnologyLiteracy from "./member-analytics/risk-exploration/TechnologyLiteracy";
import RiskHealthAccess from "./member-analytics/risk-exploration/HealthAccess";
import RiskFinancialIndex from "./member-analytics/risk-exploration/FinancialIndex";
import RiskOtherInsights from "./member-analytics/risk-exploration/OtherInsights";
import GeographicDrillDown from "./member-analytics/population-characterization/drilldown/GeographicDrillDown";
import TechnologyDrillDown from "./member-analytics/population-characterization/drilldown/TechnologyDrillDown";

// Advisory Group Pages
import AdvisoryGroupHome from './advisoryGroup-management/Home';
import AdvisoryGroupAnalytics from './advisoryGroup-management/Analytics';
import AdvisoryGroupDocumentManagement from './advisoryGroup-management/DocumentManagement';
import AdvisoryGroupOperations from './advisoryGroup-management/Operations';
import AdvisoryGroupProgramManagement from './advisoryGroup-management/ProgramManagement';
import AdvisoryGroupRecruitment from './advisoryGroup-management/Recruitment';
import AdvisoryGroupRegulatoryReporting from './advisoryGroup-management/RegulatoryReporting';


const Pages = () => {
    const router = createBrowserRouter(

        createRoutesFromElements(
            <Route element={<Layout />}>

                <Route index element={<Home />} />

                {/* Admin Views */}
                <Route path="admin" handle={{ crumb: () => 'admin-center' }} >
                    <Route index element={<Admin />} />
                    <Route path="user-management" handle={{ crumb: () => 'user-management' }} >
                        <Route index element={<UserManagement />} />
                    </Route>
                    <Route path="company-management" handle={{ crumb: () => 'company-management' }} >
                        <Route index element={<Company />} />
                        <Route path="company-create" element={<NewCompany />} handle={{ crumb: () => 'company-create' }} />
                        <Route path="edit/:id" element={<NewCompany />} handle={{ crumb: () => 'edit' }} />
                    </Route>
                    <Route path="content-management" handle={{ crumb: () => 'content-management' }} >
                        <Route index element={<ContentManagement />} />
                        <Route path="archive" element={<ArchivedContent handle={{ crumb: () => 'archive' }} />} />
                    </Route>
                    <Route path="microsoft-form" element={<EacParticipationResponses />} handle={{ crumb: () => 'microsoft-form' }} />
                    <Route path="eac-participation-response" element={<AddEACParticipationResponse />} handle={{ crumb: () => 'eac-participation-response' }} />
                    <Route path="cohort-management" handle={{ crumb: () => 'cohort-management' }}>
                        <Route index element={<CohortManagement />} />
                        <Route path="new" element={<AddCohort />} handle={{ crumb: () => 'new' }} />
                        <Route path="edit/:cohortId" element={<EditCohort />} handle={{ crumb: () => 'edit' }} />
                        <Route path="merge" element={<MergeCohort />} handle={{ crumb: () => 'merge' }} />
                        <Route path="archive" element={<ArchivedCohorts />} handle={{ crumb: () => 'archive' }} />
                        <Route path="members/:cohortId" element={<MemberList />} handle={{ crumb: () => 'members' }} />
                    </Route>
                    <Route path="cohort-upload-overview/:cohortId" element={<CohortUploadOverview />} handle={{ crumb: () => 'cohort-upload-overview' }} />
                </Route>

                {/* Quality Management Views */}
                <Route path="quality-management" handle={{ crumb: () => 'quality-management' }}>
                    <Route index element={<QualityManagementHome />} />
                    <Route path="hospital" handle={{ crumb: () => 'hospital' }}>
                        <Route index element={<QualityManagementHospitalHome />} />
                        <Route path="star-explorer" handle={{ crumb: () => 'star-explorer' }}>
                            <Route index element={<StarExplorerHome />} />
                            <Route path="national-dashboard" element={<StarExplorerNationalDashboard />} handle={{ crumb: () => 'national-dashboard' }} />
                            <Route path="state-overview" handle={{ crumb: () => 'state-overview' }} >
                                <Route index element={<StateOverview />} />
                                <Route path="state-detail" element={<StateDetail />} handle={{ crumb: () => 'state-detail' }} />
                            </Route>
                        </Route>
                        <Route path="star-report" handle={{ crumb: () => 'star-report' }} >
                            <Route index element={<ReportCard />} />
                            <Route path="mortality" element={<Mortality />} handle={{ crumb: () => 'mortality' }} />
                            <Route path="readmission" element={<Readmission />} handle={{ crumb: () => 'readmission' }} />
                            <Route path="safety" element={<Safety />} handle={{ crumb: () => 'safety' }} />
                            <Route path="patient-exp" element={<PatientExp />} handle={{ crumb: () => 'patient-exp' }} />
                            <Route path="timely" element={<Timely />} handle={{ crumb: () => 'timely' }} />
                            <Route path="multi-facility" element={<MultiFacility />} handle={{ crumb: () => 'multi-facility' }} />
                        </Route>
                        <Route path="star-planner" element={<StarPlanner />} handle={{ crumb: () => 'star-planner' }} />
                    </Route>
                </Route>

                {/* Retention Management Views */}
                <Route path="member-analytics" handle={{ crumb: () => 'member-analytics' }} >
                    <Route index element={<MemberManagementHome />} />
                    <Route path="population-characterization" handle={{ crumb: () => 'population-characterization' }} >
                        <Route index element={<PopulationCharacterization />} />
                        <Route path="core-demographics" element={<CoreDemographics />} handle={{ crumb: () => 'core-demographics' }} />
                        <Route path="geographic-dispersion" element={<GeographicDispersion />} handle={{ crumb: () => 'geographic-dispersion' }} />
                        <Route path="technology" element={<TechnologyLiteracy />} handle={{ crumb: () => 'technology' }} />
                        <Route path="health-access" element={<HealthAccess />} handle={{ crumb: () => 'health-access' }} />
                        <Route path="financial-index" element={<FinancialIndex />} handle={{ crumb: () => 'financial-index' }} />
                        <Route path="other-insights" element={<OtherInsights />} handle={{ crumb: () => 'other-insights' }} />
                        <Route path="compare-census" element={<CompareCensus />} handle={{ crumb: () => 'compare-census' }} />
                        <Route path="drilldown" handle={{ crumb: () => 'drilldown' }}>
                            <Route index element={<PopulationDrillDown />} />
                            <Route path="health-access-drill-down" element={<HealthAccessDrillDown />} handle={{ crumb: () => 'health-access-drill-down' }} />
                            <Route path="geographic-drill-down" element={<GeographicDrillDown />} handle={{ crumb: () => 'geographic-drill-down' }} />
                            <Route path="technology-drill-down" element={<TechnologyDrillDown />} handle={{ crumb: () => 'technology-drill-down' }} />
                        </Route>
                    </Route>
                    <Route path="retention-risk" handle={{ crumb: () => 'retention-risk' }} >
                        <Route index element={<RetentionRisk />} />
                        <Route path="risk-identification" element={<RiskIdentification />} handle={{ crumb: () => 'risk-identification' }} />
                        <Route path="risk-monitoring" handle={{ crumb: () => 'risk-monitoring' }} >
                            <Route index element={<RiskMonitoring />} />
                            <Route path="alert-management" element={<RiskMonitoringAlert />} handle={{ crumb: () => 'alert-management' }} />
                        </Route>
                        <Route path="risk-exploration" handle={{ crumb: () => 'risk-exploration' }} >
                            <Route index element={<RiskExploration />} />
                            <Route path="core-demographics" element={<RiskCoreDemographics />} handle={{ crumb: () => 'core-demographics' }} />
                            <Route path="geographic-dispersion" element={<RiskGeographicDispersion />} handle={{ crumb: () => 'geographic-dispersion' }} />
                            <Route path="technology" element={<RiskTechnologyLiteracy />} handle={{ crumb: () => 'technology' }} />
                            <Route path="health-access" element={<RiskHealthAccess />} handle={{ crumb: () => 'health-access' }} />
                            <Route path="financial-index" element={<RiskFinancialIndex />} handle={{ crumb: () => 'financial-index' }} />
                            <Route path="other-insights" element={<RiskOtherInsights />} handle={{ crumb: () => 'other-insights' }} />
                        </Route>
                    </Route>
                </Route>

                {/* Retention Management Views */}
                <Route path="advisory-group-management" handle={{ crumb: () => 'advisory-group-management' }} >
                    <Route index element={<AdvisoryGroupHome />} />
                    <Route path="analytics" element={<AdvisoryGroupAnalytics />} handle={{ crumb: () => 'analytics' }} />
                    <Route path="document-management" element={<AdvisoryGroupDocumentManagement />} handle={{ crumb: () => 'document-management' }} />
                    <Route path="operations" element={<AdvisoryGroupOperations />} handle={{ crumb: () => 'operations' }} />
                    <Route path="program-management" element={<AdvisoryGroupProgramManagement />} handle={{ crumb: () => 'program-management' }} />
                    <Route path="recruitment" element={<AdvisoryGroupRecruitment />} handle={{ crumb: () => 'recruitment' }} />
                    <Route path="regulatory-reporting" element={<AdvisoryGroupRegulatoryReporting />} handle={{ crumb: () => 'regulatory-reporting' }} />
                </Route>

                {/* Temporary Views */}
                <Route path="react-table" element={<CompanyReactTable />} handle={{ crumb: () => 'react-table' }} />

                {/* Common Views */}
                <Route path="/error-redirect" element={<ErrorRedirect />} />
                <Route path="/session-expired" element={<SessionExpired />} />
                <Route path="/session-logout" element={<SessionLogout />} />
                <Route path="*" element={<NotFound />} />

            </Route>
        )
    )

    return (
        <RouterProvider router={router} />
    )
}

export default Pages;
