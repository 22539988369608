import api from "../../services/api/advisoryGroupService";
import keyMirror from "../../utils/keyMirror";

export const actionTypes = keyMirror(
    {
        GET_ADVISORY_GROUP_MASTER_DATA: null,
    },
    "AdvisoryGroup"
);

// Action Creators
export const getAdvisoryGroupData = (id) => dispatch => {
    api.GetAdvisoryGroupData(id).then(response => {
        const { data } = response;
        dispatch({
            type: actionTypes.GET_ADVISORY_GROUP_MASTER_DATA,
            payload: data
        });
    });
};



// Reducer

export const initialState = {
    masterList: null,
};

export default function advisoryGroupReducer(state = initialState, { type, payload }) {
    switch (type) {
        case actionTypes.GET_ADVISORY_GROUP_MASTER_DATA:
            return {
                ...state,
                masterList: [...payload]
            }

        default:
            return state;
    }
}
