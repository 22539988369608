import React, { useEffect, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Col, Row, Button } from 'react-bootstrap';
import { getAdvisoryGroupData } from '../../ducks/advisoryGroup';
import { AppLoadContext } from '../../components/ui/AppLoadContext';
import DataGrid from '../../components/DataGrid';
import withAuth from '../../components/hoc/withAuth';
import Loader from '../../components/ui/Loader';
import { toDateTime } from '../../utils/utils';
import styles from '../../views/member-analytics/member-management/MemberManagementHome.module.scss';
import ActionBar from "../../components/ui/ActionBar";
import Icon from "../../components/ui/Icon";
import NavigationBoxDashboard from '../../components/ui/NavigationBoxDashboard';
import ContentDashboard from '../../components/ui/ContentDashboard';
import GridButton from "../../components/ui/GridButton";

export const Home = () => {
    const dispatch = useDispatch();

    const isAppLoaded = useContext(AppLoadContext);
    const advisoryGroupMaster = useSelector((state) => state.advisoryGroup.masterList);
    const currentUser = useSelector((state) => state.user.currentUser);


    useEffect(() => {
        if (isAppLoaded) {
            if (!currentUser) {
                return;
            }
            dispatch(getAdvisoryGroupData(currentUser?.companyId));
        }
        // Safe to add dispatch to the dependencies array
    }, [dispatch, isAppLoaded, currentUser]);

    const NavContent = [
        {
            path: "/advisory-group-management/analytics",
            locationName: "Advisory Group Management",
            pageName: 'Analytics',
            icon: 'analytics',
            classname: "purple",
            description: "Lorem Ipsum Dolor Sit Amet, Consetetur Sadipscing Elitr, Sed Diam Nonumy Eirmod Tempor Invidunt Ut Labore."
        },
        {
            path: "/advisory-group-management/document-management",
            locationName: "Advisory Group Management",
            pageName: 'Document Management',
            icon: 'document-management',
            classname: "yellow",
            description: "Lorem Ipsum Dolor Sit Amet, Consetetur Sadipscing Elitr, Sed Diam Nonumy Eirmod Tempor Invidunt Ut Labore."
        },
        {
            path: "/advisory-group-management/operations",
            locationName: "Advisory Group Management",
            pageName: 'Operations',
            icon: 'operations',
            classname: "darkSalmon",
            description: "Lorem Ipsum Dolor Sit Amet, Consetetur Sadipscing Elitr, Sed Diam Nonumy Eirmod Tempor Invidunt Ut Labore."
        },
        {
            path: "/advisory-group-management/program-management",
            locationName: "Advisory Group Management",
            pageName: 'Program Management',
            icon: 'program-management',
            classname: "gsApple",
            description: "Lorem Ipsum Dolor Sit Amet, Consetetur Sadipscing Elitr, Sed Diam Nonumy Eirmod Tempor Invidunt Ut Labore."
        },
        {
            path: "/advisory-group-management/recruitment",
            locationName: "Advisory Group Management",
            pageName: 'Recruitment',
            icon: 'recruitment',
            classname: "blueLight",
            description: "Lorem Ipsum Dolor Sit Amet, Consetetur Sadipscing Elitr, Sed Diam Nonumy Eirmod Tempor Invidunt Ut Labore."
        }
    ]

    const columns = [
        {
            id: 'name',
            header: 'Advisory Group Name',
            accessorKey: 'name',
            sortType: 'string',
        },
        {
            id: 'status',
            header: 'Status',
            accessorKey: 'status',
        },
        {
            id: 'noOfMembers',
            header: '# of Members',
            accessorKey: 'memberCount',
        },
        {
            id: 'noOfOpenApps',
            header: '# of Open Apps',
            accessorKey: 'appCount',
        },
        {
            id: 'NextMeetingDate',
            header: 'Next Meeting Date',
            accessorKey: 'nextMeetingTime',
            sortType: 'date',
            accessorFn: (row) => `${toDateTime(row.nextMeetingTime)}`,
        },
        {
            id: 'LastMeetingDate',
            header: 'Last Meeting Date',
            accessorKey: 'lastMeetingTime',
            accessorFn: (row) => `${toDateTime(row.lastMeetingTime)}`,
        },
        {
            id: 'userId',
            header: 'Project Mgr Name',
            accessorKey: 'firstName',
            accessorFn: (row) => `${row.firstName} ${row.lastName}`,
        },
        {
            name: 'actions',
            header: 'Actions',
            size: 170,
            cell: () => (
                <>
                    <GridButton variant="light" size="sm" className="me-1 my-1" title="Edit"
                    >
                        <Icon icon="edit" size={14} />
                    </GridButton>
                    <GridButton variant="light" size="sm" className="me-1 my-1" title="Analyze"
                    >
                        <Icon icon="analyze" size={14} />
                    </GridButton>
                    <GridButton variant="light" size="sm" className="me-1 my-1" title="Recruit"
                    >
                        <Icon icon="recruitment" size={16} />
                    </GridButton>
                    <GridButton variant="light" size="sm" className="me-1 my-1" title="Operations"
                    >
                        <Icon icon="operations" size={16} />
                    </GridButton>
                    <GridButton variant="light" size="sm" className="me-1 my-1" title="Reporting"
                    >
                        <Icon icon="regulatory-reporting" size={16} />
                    </GridButton>
                </>
            )
        },
    ];

    return (
        <Row className="h-100 mx-4 mb-4">
            <Row className="w-100 mb-4">
                <Col
                    className="d-flex  px-4"
                    md={6}>
                    <NavigationBoxDashboard data={NavContent} />
                </Col>
                <Col
                    className="d-flex flex-column px-4"
                    md={6}>
                    <div className={`${styles.tableHeading}`}>
                        Advisory Group Blog
                    </div>
                    <ContentDashboard />
                </Col>
            </Row>

            <ActionBar title="Advisory Groups">
                <Button type="button" variant="primary">
                    <Icon icon="new" size={14} className="me-2" />New
                </Button>
            </ActionBar>

            {advisoryGroupMaster === null ? <Loader className="d-flex align-items-center" animation="border" /> : (
                <div>
                    <DataGrid
                        columns={columns}
                        data={advisoryGroupMaster}
                        enableFilters={false}
                    />
                </div>
            )}
        </Row>
    );
};

export default withAuth(Home);
