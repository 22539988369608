import { msalInstance } from "../index";
import {acquireAccessToken, getAccountId} from "./api/auth";

export let ws;

export async function handleLogout(redirectURL='/') {
    try {
        let accessToken = await acquireAccessToken(msalInstance);
        let userId = await getAccountId(msalInstance);
        let wsInstance = await connect();

        localStorage.removeItem('mainNavItem');
        localStorage.removeItem('pageName');
        await sendMessage(wsInstance, JSON.stringify({accessToken: accessToken, userId: userId, didUserLogout: true}));
    } catch(error) {
        console.log(error)
    }

    await msalInstance.logoutRedirect({postLogoutRedirectUri: redirectURL});
}

export async function connect(userId, accessToken, timestamp) {
    if (ws) {
        console.log('Socket instance already created.')
        return ws;
    }

    console.log('Initializing websocket connection.')
    ws = new WebSocket(process.env.REACT_APP_PHARO_BACKEND_WEBSOCKET_API_URL);

    ws.onopen = function () {
        console.log('onopen:')
        if (!userId || !accessToken || !timestamp) {
            return;
        }

        const message = {userId: userId, accessToken: accessToken, timestamp: timestamp};
        ws.send(JSON.stringify(message));
    };

    ws.onmessage = async (e) => {
        const message = e.data;
        console.log('onmessage: ', message)

        if (message) {
            let messageObj = JSON.parse(message);
            // isForcedLogout = when a user explicitly logs out; it will log out any previously open sessions for the user
            // shouldLogout = when a user explicitly logs in; it will log out any previous open sessions for the user
            if (messageObj.isForcedLogout === true) {
                await msalInstance.logoutRedirect({postLogoutRedirectUri: `${window.location.origin}`});
            } else if (messageObj.shouldLogout === true) {
                await msalInstance.logoutRedirect({postLogoutRedirectUri: `${window.location.origin}/session-logout`});
            }
        }
    };

    ws.onclose = function (e) {
        console.log('Socket is closed.', e.reason);
        setTimeout(async function () {
            await connect(userId, accessToken, timestamp);
        }, 1000);
    };

    return ws;
}

// We must wait for an open connection before sending a message
// Reference: https://dev.to/ndrbrt/wait-for-the-websocket-connection-to-be-open-before-sending-a-message-1h12
const waitForOpenConnection = (socket) => {
    return new Promise((resolve, reject) => {
        const maxNumberOfAttempts = 10;
        const intervalTime = 200; //ms

        let currentAttempt = 0;
        const interval = setInterval(() => {
            if (currentAttempt > maxNumberOfAttempts - 1) {
                clearInterval(interval);
                reject(new Error('Maximum number of attempts exceeded'));
            } else if (socket.readyState === socket.OPEN) {
                clearInterval(interval);
                resolve();
            }
            currentAttempt++
        }, intervalTime)
    })
}

const sendMessage = async (socket, msg) => {
    if (socket.readyState !== socket.OPEN) {
        try {
            await waitForOpenConnection(socket);
            socket.send(msg);
        } catch (err) { console.error(err) }
    } else {
        socket.send(msg);
    }
}
