import withAuth from '../../../components/hoc/withAuth';
import PopulationPowerBi from './PopulationPowerBi';

const OtherInsights = () => {
    return (
        <PopulationPowerBi
            title="Other Insights"
        />
    );
};

export default withAuth(OtherInsights);
