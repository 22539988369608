import React, { useContext, useEffect, useRef, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Alert, Button, Dropdown, Form } from "react-bootstrap";
import Icon from "../../components/ui/Icon";
import styles from "./MergeCohortModal.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { AppLoadContext } from "../../components/ui/AppLoadContext";
import { getCohorts } from "../../ducks/cohort";
import { COHORT_FILE_STATUS } from "../../constants";
import { checkWhiteSpace } from "../../utils/utils";
import api from "../../services/api/cohortService";
import { toast } from "react-toastify";
import messages from "../../utils/helper/messages";
import LoadingButton from "../../components/ui/LoadingButton";
import { BsFillExclamationTriangleFill } from "react-icons/bs";


const MergeCohortModal = ({ show, onCancel, onHideCallback }) => {
    const dispatch = useDispatch();

    const isAppLoaded = useContext(AppLoadContext);
    const cohorts = useSelector((state) => state.cohort.cohorts);
    const currentUser = useSelector((state) => state.user.currentUser);
    const inputRef = useRef(null);

    const [mergedCohortName, setMergedCohortName] = useState('');
    const [selectedCohorts, setSelectedCohorts] = useState([]);
    const [cohortList, setCohortList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (isAppLoaded) {
            if (!currentUser) {
                return;
            }
            dispatch(getCohorts(currentUser?.companyId));
        }
        // Safe to add dispatch to the dependencies array
    }, [dispatch, isAppLoaded, currentUser]);

    useEffect(() => {
        const validatedCohorts = cohorts?.filter(cohort => cohort.status === COHORT_FILE_STATUS.VALIDATED);
        setCohortList(validatedCohorts);
    }, [cohorts]);

    const handleCohortSelect = (id) => {
        let selectedCohort = cohortList.filter((cohort) => id === cohort.id);
        setSelectedCohorts((prevState) => [...prevState, ...selectedCohort]);
    };

    const handleCohortDeselect = (id) => {
        setSelectedCohorts((prevState) => [
            ...prevState.filter((cohort) => id !== cohort.id),
        ]);
    };

    const isValid = () => {
        const hasWhiteSpace = checkWhiteSpace(mergedCohortName);

        return !(
            selectedCohorts.length === 0 ||
            mergedCohortName === "" ||
            selectedCohorts.length <= 1 ||
            hasWhiteSpace
        )
    }

    const handleMerge = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        const cohortsData = {
            companyId: currentUser?.companyId,
            uploaderUserId: currentUser?.id,
            cohortName: mergedCohortName,
            originalFileNames: selectedCohorts.map(s => s.fileName),
            cohortIds: selectedCohorts.map(s => s.id),
            fileSize: selectedCohorts.reduce((a, v) => a = a + v.size, 0)
        }

        try {
            await api.mergeCohorts(cohortsData).then(() => {
                setIsLoading(false);
                toast(`${messages.success.mergeCohortMember}`, { type: "success", toastId: "merge-cohort-success" });

                setSelectedCohorts([]);
                onCancel();
            });
        }
        catch (error) {
            setIsLoading(false);
            toast(`${messages.error.prefix} ${error}.`, { type: "error", toastId: "merge-cohort-error" });
        }
    };

    const onSelectionChange = (e, val) => {
        e.target.checked ? handleCohortSelect(val) : handleCohortDeselect(val);
    }

    return (
        <Modal show={show} fullscreen={false} backdrop="static" onHide={onHideCallback} size="md">
            <Modal.Header closeButton>
                <Modal.Title>Merge Cohort</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="px-xl-3 py-xl-2">
                    <div className="hstack gap-2 justify-content-end mb-3">
                        <Button variant="outline-primary" onClick={onCancel}>
                            Cancel
                        </Button>
                        <LoadingButton
                            type="submit"
                            className="flex-shrink-0"
                            disabled={!isValid() || isLoading}
                            loading={isLoading}
                            onClick={handleMerge}
                        >
                            Merge
                        </LoadingButton>
                    </div>

                    <Form.Group>
                        <Form.Label>Cohort Name</Form.Label>
                        <Dropdown className={`${styles.cohortSelector} w-100`} autoClose="outside">
                            <Dropdown.Toggle variant="light" id="dropdown-autoclose-true">
                                Select Cohorts to Merge
                            </Dropdown.Toggle>

                            <Dropdown.Menu className="py-2">
                                {
                                    cohortList?.length ?
                                        cohortList.map((cohort) => (
                                            <div className="px-3" key={cohort.id}>
                                                <Form.Check
                                                    type="checkbox"
                                                    id={`cohort-${cohort.id}`}
                                                    label={cohort.name}
                                                    onChange={(e) => onSelectionChange(e, cohort.id)}
                                                />
                                            </div>
                                        ))
                                        :
                                        <div className="px-3 text-muted text-center">
                                            No validated cohorts
                                        </div>
                                }
                            </Dropdown.Menu>
                        </Dropdown>
                    </Form.Group>

                    <div className="my-3 text-center">
                        <Icon icon="merge" size={32} className={styles.mergeIcon} />
                    </div>

                    <Form.Group className="mb-3">
                        <Form.Label>New Cohort Name</Form.Label>
                        <Form.Control
                            type="text"
                            ref={inputRef}
                            value={mergedCohortName}
                            onChange={(e) => setMergedCohortName(e.target.value)}
                        />
                    </Form.Group>

                    <Alert
                        variant="warning"
                        className="mt-2 py-2 lh-sm"
                    >
                        <div className="d-flex align-items-center">
                            <BsFillExclamationTriangleFill className="me-2 flex-shrink-0" />
                            {messages.error.cohortNameValidation}
                        </div>
                    </Alert>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default MergeCohortModal;
