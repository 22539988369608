import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import React from "react";

const OverflowText = ({children}) => {
    return (
        <OverlayTrigger
            placement="top"
            overlay={<Tooltip style={{position: "fixed"}}>{children}</Tooltip>}
        >
            <div className="text-truncate mw-100">{children}</div>
        </OverlayTrigger>
    )
}

export default OverflowText;
