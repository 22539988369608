import React, { useContext, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { deleteCompany, getCompany, getCompanyLogo } from '../../ducks/company'
import { AppLoadContext } from "../../components/ui/AppLoadContext";
import DataGrid from "../../components/DataGrid";
import { FaPencilAlt, FaTrashAlt, FaPlus } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
import { USER_ROLE } from "../../constants";
import Loader from "../../components/ui/Loader";
import withAuth from "../../components/hoc/withAuth";
import { formatPhoneNumber, isUserSysAdmin } from "../../utils/utils";
import { toast } from 'react-toastify';
import messages from "../../utils/helper/messages";
import ActionBar from "../../components/ui/ActionBar";
import ConfirmModal from "../../components/ui/ConfirmModal";
import OverflowText from "../../components/ui/OverflowTooltip";


function Company() {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [confirmModal, setConfirmModal] = useState(false);
    const [info, setInfo] = useState(null);

    const isAppLoaded = useContext(AppLoadContext);
    const companies = useSelector((state) => state.company.companies);
    const currentUser = useSelector((state) => state.user.currentUser);

    const handleEdit = ({ id }) => {
        navigate(`/admin/company-management/edit/${id}`);
    }

    const handleConfirmClose = () => {
        setConfirmModal(false);
        setInfo(null);
    }

    const handleConfirm = () => {
        handleDelete(info);
        handleConfirmClose();
    }

    const handleDeleteConfirm = (data) => {
        setInfo(data);
        setConfirmModal(true);
    }

    const handleDelete = (e) => {
        dispatch(deleteCompany({
            ...e,
            recordStatus: 'A'
        }))
        toast(`${messages.success.deleteCompany}`, { type: "success", toastId: "delete-company-success" })
    }

    const rowActions = ({ row }) => {
        return (
            <div className="d-flex">
                <Button variant="light" size="sm" className="me-2 my-1"
                    disabled={(!isUserSysAdmin(currentUser) && row.original.recordStatus === 'P')} onClick={() => handleEdit(row.original)}>
                    <FaPencilAlt />
                </Button>
                <Button variant="light" size="sm" className="my-1"
                    disabled={(!isUserSysAdmin(currentUser) && row.original.recordStatus === 'P')} onClick={() => handleDeleteConfirm(row.original)}>
                    <FaTrashAlt />
                </Button>
            </div>
        )
    }

    const columns = [
        {
            id: 'logo',
            header: 'Logo',
            size: 160,
            cell: ({ row }) => {
                return (
                    <img
                        src={
                            row.original.fileURL
                                ? row.original.fileURL?.imageSrc
                                : ''
                        }
                        alt={row.original.logo}
                        height={40}
                        width={'auto'}
                    />
                )
            },
        },
        {
            id: 'name',
            header: 'Company Name',
            accessorKey: 'name',
            cell: ({ row, cell }) => (
                <OverflowText id={cell.id}>{row.original.name}</OverflowText>
            ),
            maxSize: 200,
        },
        {
            id: 'fullName',
            header: 'Contact Person',
            cell: ({ row, cell }) => {
                let personName = `${row.original.contactFirstName} ${row.original.contactLastName}`;
                return <OverflowText id={cell.id}>{personName}</OverflowText>;
            },
            maxSize: 170,
        },
        {
            id: 'contactEmail',
            header: 'Email',
            accessorKey: 'contactEmail',
            cell: ({ row, cell }) => (
                <OverflowText id={cell.id}>
                    {row.original.contactEmail}
                </OverflowText>
            ),
            maxSize: 170,
        },
        {
            id: 'contactPhone',
            header: 'Phone',
            accessorKey: 'contactPhone',
            cell: ({ row }) => (
                <div className="text-nowrap">
                    {formatPhoneNumber(row.original.contactPhone)}
                </div>
            ),
            size: 120,
        },
        {
            id: 'address',
            header: 'Company Address',
            accessorKey: 'address',
            enableSorting: false,
            cell: ({ row, cell }) => (
                <OverflowText id={cell.id}>{row.original.address}</OverflowText>
            ),
            maxSize: 200,
        },
        {
            id: 'city',
            header: 'City',
            accessorKey: 'city',
        },
        {
            id: 'state',
            header: 'State',
            accessorKey: 'state',
            size: 100,
        },
        {
            id: 'portalCode',
            header: 'Portal',
            accessorKey: 'portalCode',
            size: 110,
        },
        {
            name: 'actions',
            header: 'Actions',
            cell: rowActions,
            size: 90,
        },
    ];

    useEffect(() => {
        if (isAppLoaded && currentUser) {
            isUserSysAdmin(currentUser) ? dispatch(getCompany()) : dispatch(getCompany(currentUser?.companyId));
        }
    }, [dispatch, isAppLoaded, currentUser]);

    useEffect(() => {
        if (companies && companies.length > 0) {
            companies.map((item) => {
                return dispatch(getCompanyLogo(item.id));
            })
        }
    }, [companies?.length, dispatch]);


    return (
        <>
            <ActionBar>
                {
                    currentUser?.roleId === USER_ROLE.SYSTEM_ADMIN ?
                        <Button
                            onClick={() => navigate(`/admin/company-management/company-create`)}
                            variant="primary"
                        >
                            <FaPlus size={14} className="me-1" />Add Company
                        </Button> : null
                }
            </ActionBar>

            {companies === null ? <Loader /> :
                <DataGrid
                    columns={columns}
                    data={companies}
                    enableFilters={false}
                />}

            <ConfirmModal
                show={confirmModal}
                message={messages.confirm.deleteCompany}
                variant="danger"
                onConfirm={handleConfirm}
                onCancel={handleConfirmClose}
                onHideCallback={handleConfirmClose}
            />
        </>
    );
}

export default withAuth(Company);
