import { apiInstance } from '../apiInstance';
import { protectedResources } from '../../msalAuthConfig';

const headers = {
    'Content-Type': 'application/json',
};

const contentApi = {
    GetAdvisoryGroupData: async (id) => {
        return await apiInstance({
            method: 'GET',
            url: `${protectedResources.apiCompany.endpoint}/${id}/advisoryGroup`,
            headers,
        });
    },
};

export default contentApi;
