import withAuth from '../../../components/hoc/withAuth';
import PopulationPowerBi from './PopulationPowerBi';

const FinancialIndex = () => {
    return (
        <PopulationPowerBi
            title="Financial Index"
        />
    );
};

export default withAuth(FinancialIndex);
