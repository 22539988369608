const ActionBar = ({ title, back = false, children }) => {
    const wrapperStyles = (title || back) ? 'justify-content-between' : 'justify-content-end';

    return (
        <div className={`d-flex align-items-center flex-shrink-0 mb-3 ${wrapperStyles}`}>
            {title && <h1 className="action-title mb-0">{title}</h1>}
            {children}
        </div>
    );
};

export default ActionBar;
