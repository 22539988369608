function Placeholder(props) {
  const style = props.customStyle ? props.customStyle : '';

  return (
    <div className={`bg-light p-3 h-100 d-flex justify-content-center align-items-center h4 ${style}`}>
      { props.title }
    </div>
  );
}

export default Placeholder;
