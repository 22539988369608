import React, { useMemo } from 'react';
import { useDropzone } from 'react-dropzone';
import styles from '../../styles/AddCohort.module.scss';

const baseStyle = { borderColor: '#e5e5e5' };
const focusedStyle = { borderColor: '#1476bd' };
const acceptStyle = { borderColor: '#198754' };
const rejectStyle = { borderColor: '#dc3545' };

const FileUploader = ({ onDropAccepted }) => {
    const {
        getRootProps,
        getInputProps,
        isFocused,
        isDragActive,
        isDragAccept,
        isDragReject,
    } = useDropzone({
        accept: {
            'text/csv': [],
            'application/vnd.ms-excel': [],
        },
        maxFiles: 4,

        onDropRejected: fileRejections => {
            console.log('onDropRejected', fileRejections);
        },
        onDropAccepted: acceptFiles => {
            console.log('onDropAccepted')
            onDropAccepted(acceptFiles);
        },
        onFileDialogOpen: () => {
            console.log('onFileDialogOpen')
        },
        onDrop(acceptedFiles, rejectedFiles) {
            console.log('onDrop', acceptedFiles, rejectedFiles)
        }
    });

    const style = useMemo(() => ({
        ...baseStyle,
        ...(isFocused ? focusedStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [
        isFocused,
        isDragAccept,
        isDragReject
    ]);

    return (
        <div {...getRootProps({ className: styles.dropzone, style })}>
            <input {...getInputProps()} />
            {!isDragActive && <p className="mb-0 text-center">Drag and drop your files here,<br />or click to select files</p>}
            {isDragActive && !isDragReject && <p className="mb-0 text-center text-success">File type accepted, thanks!</p>}
            {isDragReject && <p className="mb-0 text-center text-danger">File type not accepted, sorry!</p>}
        </div>
    );
};

export default FileUploader;
