import { Report } from 'powerbi-report-component';
import React from 'react';
import { reportLayoutSettings } from '../../constants';
import { setPowerBiReport } from '../../ducks/report';
import { useDispatch } from 'react-redux';

const PowerbiReport = ({ accessToken, embedUrl, embedId, reportName, reportSlicerState }) => {
    const dispatch = useDispatch()
    const myReportConfig = {
        embedType: 'report',
        tokenType: 'Embed',
        accessToken: accessToken,
        embedUrl: `${embedUrl}`,
        embedId: embedId,
        reportMode: "View", // "Edit"
        permissions: "View", // "All" (when using "Edit" mode)

        extraSettings: {
            filterPaneEnabled: false,
            navContentPaneEnabled: false,
            ...reportLayoutSettings, // layout config
        },
    };

    const getSlicerState = (slicers) => {
        let filteredSlicers = [];
        slicers.forEach(async slicer => {
            slicer.getSlicerState().then(slicerState => {
                if (slicerState.filters.length > 0) filteredSlicers.push(slicerState);
            })
        })

        return filteredSlicers;
    }


    const getSlicer = async () => {
        // Retrieve the page collection and get the visuals for the active page.
        try {
            const pages = await window.report.getPages();
            // Retrieve the active page.
            let pageWithSlicer = pages.filter(function (page) {
                return page.isActive;
            })[0];

            const visuals = await pageWithSlicer.getVisuals();

            // Retrieve the target visual.
            let slicers = visuals.filter(function (visual) {
                return visual.type === "slicer";
            });
            // Some reports don't have slicer.
            if (slicers) {
                // Get the slicer state
                const filteredSlicers = getSlicerState(slicers);

                dispatch(setPowerBiReport({ data: filteredSlicers, reportName: reportName }));
            }
        }
        catch (errors) {
            console.log(errors);
        }

    }

    const setSlicer = async (slicerParams) => {
        try {
            const pages = await window.report.getPages();

            // Retrieve the active page.
            let page = pages.filter(function (page) {
                return page.isActive;
            })[0];

            const visuals = await page.getVisuals();

            // Retrieve the target visual.
            let slicers = visuals.filter(function (visual) {
                return visual.type === "slicer";
            });

            // Some reports don't have slicer.
            if (slicers) {
                await slicers.forEach(async (slicer) => {

                    const existingSlicerState = await slicer.getSlicerState();
                    if (existingSlicerState.targets.length > 0) {
                        const newSlicerState = slicerParams.filter(function (slicerState) {
                            if (slicerState.targets.length > 0) {
                                return slicerState.targets[0]?.column === existingSlicerState.targets[0].column;
                            }
                            return null;
                        })[0];
                        if (newSlicerState) {
                            slicer.setSlicerState(newSlicerState);
                        }
                    }
                });

                console.log("Date slicer was set.");
            }
        }
        catch (errors) {
            console.log(errors);
        }
    }


    return (
        <Report  {...myReportConfig}

            onLoad={(report) => {
                window.report = report;
                if (reportSlicerState)
                    setSlicer(reportSlicerState)
            }}

            onSelectData={() => {
                getSlicer();
            }}
        />
    );
};

export default PowerbiReport;
