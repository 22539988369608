import React, { useState, useEffect, useMemo, useRef } from "react";
import { Table, Form, Button } from 'react-bootstrap';
import {
    getPaginationRowModel,
    getSortedRowModel,
    flexRender,
    getCoreRowModel,
    getFacetedRowModel,
    useReactTable,
    getFilteredRowModel,
    getFacetedUniqueValues,
    getFacetedMinMaxValues,
} from '@tanstack/react-table';
import { FaSortUp, FaSortDown, FaFilter } from "react-icons/fa";
import styles from './DataGrid.module.scss';
import { rankItem } from '@tanstack/match-sorter-utils';
import Icon from "./ui/Icon";
import {
    MdKeyboardArrowLeft,
    MdKeyboardArrowRight,
    MdKeyboardDoubleArrowLeft,
    MdKeyboardDoubleArrowRight
} from "react-icons/md";

const FilterItem = ({ filter, onSelect, active }) => {
    return (
        <li
            onClick={() => onSelect(filter)}
            className={active && 'active'}
        >
            {filter}
        </li>
    )
}

const RowHeader = ({ header, table }) => {
    const [width, setWidth] = useState(0);
    const targetRef = useRef(null);
    const divRef = useRef(null);

    const firstValue = table.getPreFilteredRowModel().flatRows[0]?.getValue(header.column.id);
    const [isFilterSelected, setFilterSelected] = useState(false);
    const [isDivVisible, setDivVisibility] = useState(false);

    const handleItemClick = (item) => {
        if (item === 'None') {
            header.column.setFilterValue('');
            setFilterSelected(false);
        }
        else {
            setWidth(targetRef?.current.clientWidth + 1);
            header.column.setFilterValue(item);
            setFilterSelected(true);
        }
        setDivVisibility(false);
    };

    const handleIconClick = (event) => {
        event.stopPropagation();
        setWidth(targetRef?.current.clientWidth + 1);
        setDivVisibility(!isDivVisible);
    };

    const sortedUniqueValues = useMemo(
        () =>
        typeof firstValue === "number"
            ? []
            : Array.from(header.column.getFacetedUniqueValues().keys()).sort(),
        [header.column.getFacetedUniqueValues()]
    )
    const sortedUniqueValuesWithNull = ['None', ...sortedUniqueValues];

    useEffect(() => {
        const handleClickOutside = (event) => {
            const icon = document.querySelector(`[data-filter='${header.id}']`);
            if (divRef.current && !divRef.current.contains(event.target) && !icon.contains(event.target)) {
                setDivVisibility(false);
            }
        };
        document.querySelector('table').addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        setWidth(targetRef?.current.clientWidth + 1);
        function handleResize() {
            setWidth(targetRef?.current.clientWidth + 1);
        }
        window.addEventListener('resize', handleResize)
        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, []);

    return (
        <>
            <div
                className="d-flex flex-nowrap"
                data-id={header.id}
                ref={targetRef}>
                <div
                    {...{
                        className: header.column.getCanSort()
                            ? 'cursor-pointer select-none flex-grow-1 ps-2'
                            : 'flex-grow-1 ps-2',
                        onClick: header.column.getToggleSortingHandler(),
                    }}>
                    {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                    )}
                    {{
                        asc: (
                            <FaSortUp
                                className={`position-relative ${styles.sortUp}`}
                            />
                        ),
                        desc: (
                            <FaSortDown
                                className={`position-relative ${styles.sortDown}`}
                            />
                        ),
                    }[header.column.getIsSorted().toString()] ?? null}
                </div>
                {header.column.getCanFilter() ? (
                    <div className={`${styles.filterParent} flex-shrink-0 px-2`}>
                        <div className="cursor-pointer">
                            {
                                !isFilterSelected ? (
                                    <Icon data-filter={header.id} icon="filter" size={16} onClick={handleIconClick}/>
                                )
                                : (
                                    <FaFilter data-filter={header.id} className={styles.filterSelected} onClick={handleIconClick}/>
                                )
                            }
                        </div>
                    </div>
                ) : null}
            </div>
            <div>{isDivVisible &&
                    <div ref={divRef} className={`${styles.filterItemsParent} flex-shrink-0`}
                         style={{width: width}}>
                        <ul className={styles.dropdownContent}>
                            {sortedUniqueValuesWithNull.map((filter) => (
                                <FilterItem
                                    key={filter}
                                    filter={filter}
                                    onSelect={() => handleItemClick(filter)}
                                    active={filter === header.column.getFilterValue()}
                                />
                            ))}
                        </ul>
                    </div>
                }
            </div>
        </>
    )
};

const DataGrid = ({data, columns, enableFilters, rowClick, sort, columnDisplay}) => {

    const [sorting, setSorting] = useState(sort || []);
    const [columnVisibility, setColumnVisibility] = useState(columnDisplay || {});
    const pageSizes = [10, 20, 30, 40, 50];

    const [columnFilters, setColumnFilters] = useState([])
    const [globalFilter, setGlobalFilter] = useState('');

    const fuzzyFilter = (row, columnId, value, addMeta) => {
        const itemRank = rankItem(row.getValue(columnId), value)
        addMeta({
          itemRank
        })
        return itemRank.passed
    }

    const table = useReactTable({
        data,
        defaultColumn: {
            size: undefined,
            minSize: 60,
            maxSize: 300,
        },
        columns,
        filterFns: {
            fuzzy: fuzzyFilter
        },
        state: {
            sorting,
            columnVisibility,
            columnFilters,
            globalFilter,
        },
        onColumnFiltersChange: setColumnFilters,
        onSortingChange: setSorting,
        enableSortingRemoval: false,
        onColumnVisibilityChange: setColumnVisibility,
        getSortedRowModel: getSortedRowModel(),
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        globalFilterFn: fuzzyFilter,
        onGlobalFilterChange: setGlobalFilter,
        getFacetedRowModel: getFacetedRowModel(),
        getFacetedUniqueValues: getFacetedUniqueValues(),
        getFacetedMinMaxValues: getFacetedMinMaxValues(),
        enableColumnFilters: enableFilters,
    });

    useEffect(() => {
        if (table.getState().columnFilters[0]?.id === "fullName") {
            if (table.getState().sorting[0]?.id !== "fullName") {
                table.setSorting([{ id: "fullName", desc: false }])
            }
        }
    }, [table.getState().columnFilters[0]?.id])

    return (
        <>
        <div className={`${styles.dataGridContainer} rounded bg-white position-relative`}>
            <Table bordered striped hover responsive className={styles.dataGrid}>
                <thead>
                    {table.getHeaderGroups().map(headerGroup => (
                        <tr key={headerGroup.id}>
                            {headerGroup.headers.map(header => (
                                <th key={header.id}
                                    style={{
                                        width: header.column.columnDef.size ?? undefined,
                                        maxWidth: header.column.columnDef.maxSize ?? undefined,
                                        minWidth: header.column.columnDef.minSize ?? undefined,
                                    }}
                                    className={'px-0'}
                                >
                                    {header.isPlaceholder ? null : (
                                        <RowHeader header={header} table={table} />
                                    )}
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>

                <tbody>
                    {table.getRowModel().rows.length === 0 && (
                        <tr>
                            <td
                                className="text-center text-secondary py-2"
                                colSpan={
                                    // TODO: Will need to update if we use column groups
                                    table.getHeaderGroups()[0].headers.length
                                }>
                                No data
                            </td>
                        </tr>
                    )}
                    {table.getRowModel().rows.map(row => (
                        <tr key={row.id} style={{ cursor: rowClick ? 'pointer' : 'auto' }}>
                            {row.getVisibleCells().map(cell => (
                                <td key={cell.id}
                                    onClick={() => {
                                        if (rowClick) {
                                            rowClick(row.original);
                                        }
                                    }}
                                    style={{maxWidth: cell.column.columnDef.maxSize}}
                                    data-id={cell.id}
                                >
                                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </Table>
        </div>
        {
            table.getPageCount() > 1 ?
                <div className={`d-flex justify-content-between mt-3 pt-1 ${styles.pagination}`}>
                    <div className="d-flex align-items-center">
                        <Form.Select
                            value={table.getState().pagination.pageSize}
                            onChange={e => {
                                table.setPageSize(Number(e.target.value))
                            }}
                            size="sm"
                        >
                            <>
                                {pageSizes.map(pageSize => (
                                    <option key={pageSize} value={pageSize}>
                                        Show {pageSize}
                                    </option>
                                ))}
                            </>
                        </Form.Select>
                        <span className="fw-bold text-nowrap ms-2">per page</span>
                    </div>
                    <div className="d-flex align-items-center">
                        {
                            table.getPageCount() > 2 &&
                            <Button
                                onClick={() => table.setPageIndex(0)}
                                disabled={!table.getCanPreviousPage()}
                                variant="link"
                                size="sm"
                                className="d-flex align-items-center ps-1"
                            >
                                <MdKeyboardDoubleArrowLeft className="me-1" /> First
                            </Button>
                        }
                        <Button
                            onClick={() => table.previousPage()}
                            disabled={!table.getCanPreviousPage()}
                            variant="link"
                            size="sm"
                            className="d-flex align-items-center ps-1"
                        >
                            <MdKeyboardArrowLeft className="me-1" /> Previous
                        </Button>
                        <span className="d-flex align-items-center mx-2">
                            <span>Page</span>
                            <Form.Control
                                type="number"
                                value={table.getState().pagination.pageIndex + 1}
                                onChange={e => {
                                    const page = e.target.value ? Number(e.target.value) - 1 : 0
                                    table.setPageIndex(page)
                                }}
                                size="sm"
                                className={`mx-2 ${styles.paginationInput}`}
                            />
                            <span className="text-nowrap">
                                of{' '}{table.getPageCount()}
                            </span>
                        </span>
                        <Button
                            onClick={() => table.nextPage()}
                            disabled={!table.getCanNextPage()}
                            variant="link"
                            size="sm"
                            className="d-flex align-items-center pe-1"
                        >
                            Next <MdKeyboardArrowRight className="ms-1" />
                        </Button>
                        {
                            table.getPageCount() > 2 &&
                            <Button
                                onClick={() => table.setPageIndex(table.getPageCount() - 1)}
                                disabled={!table.getCanNextPage()}
                                variant="link"
                                size="sm"
                                className="d-flex align-items-center pe-1"
                            >
                                Last <MdKeyboardDoubleArrowRight className="ms-1" />
                            </Button>
                        }
                    </div>
                </div>
                : null
        }
        </>
    );
}

export default DataGrid;
