const messages = {
    success: {
        // Company
        createCompany: 'Company created successfully',
        updateCompany: 'Company updated successfully',
        deleteCompany: 'Company deleted successfully',

        // User
        createUser: 'User created successfully',
        updateUser: 'User profile updated successfully',
        archiveUser: 'User archived successfully',
        unarchiveUser: 'User unarchived successfully',
        inviteUser: 'User invited successfully',

        // Content
        createContent: 'Content created successfully',
        updateContent: 'Content updated successfully',
        archiveContent: 'Content archived successfully',

        // Cohort
        cohortUpload: 'uploaded successfully',
        deleteCohort: 'Cohort archived successfully',
        updateCohortMember: 'Member updated successfully',
        mergeCohortMember: 'Cohort merged successfully',
        scheduleCohort: 'Cohort scheduled successfully',
    },
    error: {
        // General
        prefix: 'Error:',

        // Cohort
        cohortUpload: 'Error in uploading',
        cohortNameValidation: 'Cohort name should not be empty and should not have a leading or trailing white space or multiple spaces.',
        userDetailsValidation: 'User details should not be empty.'
    },
    confirm: {
        // Company
        deleteCompany: 'Are you sure you want to delete the company?',

        // Content
        archiveContent: 'Are you sure you want to archive the content?',

        // User
        archiveUser: 'Are you sure you want to archive the user?',
        unarchiveUser: 'Are you sure you want to unarchive the user?',

        // Cohort
        deleteCohort: 'Are you sure you want to archive the cohort?',
    },
    info: {
        // Cohort
        mergeCohortNoRecord: 'No validated cohorts available',
    }
}

export default messages;
