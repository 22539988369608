import api from '../../services/api/cohortService';
import memberApi from '../../services/api/memberAnalyticsService';
import keyMirror from '../../utils/keyMirror';

export const actionTypes = keyMirror(
    {
        GET_ALL_COHORTS: null,
        GET_ARCHIVED_COHORTS: null,
        GET_ALL_COHORTS_NOTIFICATIONS: null,
        GET_MEMBERS_BY_COHORT_ID: null,
        DELETE_COHORTS: null,
        UPDATE_COHORT_MEMBERS: null,
        SCHEDULE_COHORTS: null,
    },
    'Cohort'
);

// Action Creators

export const getCohorts = (id) => dispatch => {

    api.getCohortData(id).then(response => {
        const { data } = response;
        dispatch({
            type: actionTypes.GET_ALL_COHORTS,
            payload: data,
        });
    });
}

export const getCohortsByStatus = (id, status) => dispatch => {
    api.getCohortDataByStatus(id, status).then(response => {
        const { data } = response;
        dispatch({
            type: actionTypes.GET_ARCHIVED_COHORTS,
            payload: data,
        });
    });
}

export const getCohortNotifications = (id) => dispatch => {

    api.getCohortNotifications(id).then(response => {
        const { data } = response;
        dispatch({
            type: actionTypes.GET_ALL_COHORTS_NOTIFICATIONS,
            payload: data,
        });
    });
}

export const getMembersByCohortId = (id) => dispatch => {
    memberApi.getMembersData(id).then(response => {
        const { data } = response;
        dispatch({
            type: actionTypes.GET_MEMBERS_BY_COHORT_ID,
            payload: data,
        });
    });
}

export const archiveCohort = (cohortId, successHandler) => dispatch => {
    api.archiveCohort(cohortId).then(() => {
        dispatch({
            type: actionTypes.DELETE_COHORTS,
            payload: cohortId,
        });
        successHandler();
    });
}

export const scheduleCohort = (cohortId, successHandler) => dispatch => {
    api.scheduleCohort(cohortId).then(() => {
        dispatch({
            type: actionTypes.SCHEDULE_COHORTS,
            payload: cohortId,
        });
        successHandler();
    });
}

export const updateMember = (memberData) => dispatch => {
    memberApi.updateMember(memberData).then(() => {
        dispatch({
            type: actionTypes.UPDATE_COHORT_MEMBERS,
            payload: memberData,
        });
    });
}

// Reducer

export const initialState = {
    cohorts: null,
    archiveCohorts: null,
    notifications: null,
    members: null
};

export default function cohortReducer(state = initialState, { type, payload }) {
    switch (type) {
        case actionTypes.GET_ALL_COHORTS:
            return {
                ...state,
                cohorts: payload,
            };
        case actionTypes.GET_ARCHIVED_COHORTS:
            return {
                ...state,
                archiveCohorts: payload,
            };
        case actionTypes.GET_ALL_COHORTS_NOTIFICATIONS:
            return {
                ...state,
                notifications: payload,
            };
        case actionTypes.GET_MEMBERS_BY_COHORT_ID:
            return {
                ...state,
                members: payload,
            };
        case actionTypes.DELETE_COHORTS: {
            let cohortsCopy = [...state.cohorts];
            const cohortIndex = cohortsCopy.findIndex(element => element.id === payload);
            cohortsCopy.splice(cohortIndex, 1);
            return {
                ...state,
                cohorts: [...cohortsCopy],
                archiveCohorts: [...state.archiveCohorts, cohortsCopy[cohortIndex]]
            };
        }
        case actionTypes.SCHEDULE_COHORTS: {
            let cohortsCopy = [...state.cohorts];
            const cohortIndex = cohortsCopy.findIndex(element => element.id === payload);
            cohortsCopy[cohortIndex].status = 'Scheduled'
            return {
                ...state,
                cohorts: [...cohortsCopy]
            };
        }
        case actionTypes.UPDATE_COHORT_MEMBERS: {
            let membersCopy = [...state.members];
            const memberIndex = membersCopy.findIndex(element => element.cohortId === payload.cohortId && element.memberId === payload.memberId);
            if (payload.status === 'R') {
                membersCopy.splice(memberIndex, 1);
            }
            else {
                membersCopy[memberIndex] = { ...payload };
            }
            return {
                ...state,
                members: [...membersCopy]
            };
        }
        default:
            return state;
    }
};
