import Report from '../../starExplorer/report';
import Placeholder from '../../../components/ui/Placeholder';
import PopulationActionbar from "./PopulationActionbar";
import { useSelector } from 'react-redux'

const PopulationPowerBi = ({ reportConfig, title }) => {
    const reportSlicerState = useSelector((state) => state.report.populationCharacterizationSlicerState);

    return (
        <div className="h-100 d-flex flex-column">
            <PopulationActionbar />
            <div className="embed-wrapper h-100">
                {reportConfig ? (
                    <Report {...reportConfig} reportSlicerState={reportSlicerState} />
                ) : (
                    <Placeholder title={`Power BI - ${title}`} />
                )}
            </div>
        </div>
    );
};

export default PopulationPowerBi;
