import React, { useEffect, useState } from 'react';
import SharedForm from '../../components/ui/Form';
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux'
import { createEACResponseParams } from '../../ducks/eacParticipationResponses';
import withAuth from "../../components/hoc/withAuth";
import { toast } from 'react-toastify';
import messages from "../../utils/helper/messages";

function AddEACParticipationResponse() {

    const [startTime, setStartTime] = useState(new Date());

    const arr = [
        { type: "text", key: "firstName", labelName: "First Name", required: true },
        { type: "text", key: "lastName", labelName: "Last Name", required: true },
        { type: "text", key: "memberId", labelName: "Member Id", maxLength: 100, required: false },
        { type: "text", key: "address", labelName: "Address", required: true, maxLength: 150 },
        { type: "text", key: "city", labelName: "City", required: true, maxLength: 80 },
        { type: "text", key: "state", labelName: "State", required: true, maxLength: 80 },
        { type: "text", key: "postalCode", labelName: "Postal Code", required: true, maxLength: 5 },
        { type: "email", key: "email", labelName: "Email", required: false },
        { type: "tel", key: "phone", labelName: "Phone", required: true, maxLength: 15 },
        {
            type: "radio", key: "phoneType", labelName: "Phone Type", required: false,
            optionValues: [
                { id: 'landline', value: "Landline", label: "Landline" },
                { id: 'mobile', value: "Mobile", label: "Mobile" }
            ]
        },
        {
            type: "radio", key: "methodOfCommunication", labelName: "Method Of Communication", required: false,
            optionValues: [
                { id: 'email', value: "Email", label: "Email" },
                { id: 'phone', value: "Phone", label: "Phone" },
                { id: 'mail', value: "Mail", label: "Mail" }
            ]
        }, {
            type: "radio", key: "primaryLanguage", labelName: "Primary Language", required: false,
            optionValues: [
                { id: 'english', value: "English", label: "English" },
                { id: 'spanish', value: "Spanish", label: "Spanish" },
                { id: 'other', value: "Other", label: "Other" },
            ]
        },
        {
            type: "radio", key: "needTranslator", labelName: "Need Translator", required: false,
            optionValues: [
                { id: 'yes', label: 'Yes', value: "Yes" },
                { id: 'no', label: 'No', value: "No" }
            ]
        },
        {
            type: "radio", key: "needTransportation", labelName: "Need Transportation", required: false,
            optionValues: [
                { id: 'yes', label: 'Yes', value: "Yes" },
                { id: 'no', label: 'No', value: "No" },
                { id: 'sometimes', value: "Sometimes", label: "Sometimes" }
            ]
        },
        {
            type: "radio", key: "internet", labelName: "Internet", required: false,
            optionValues: [
                { id: 'yes', label: 'Yes', value: "Yes" },
                { id: 'no', label: 'No', value: "No" },
                { id: 'unsure', value: "Unsure", label: "Unsure" }
            ]
        },
        {
            type: "radio", key: "accommodation", labelName: "Accommodation", required: false,
            optionValues: [
                { id: 'yes', label: 'Yes', value: "Yes" },
                { id: 'no', label: 'No', value: "No" }
            ]
        },
        {
            type: "text", key: "accommodationNeeds", labelName: "Accommodation Needs", maxLength: 100, required: false,
            hidden: true, conditional: true, dependsOn: 'accommodation', dependsValue: ["No"],
        },
        {
            type: "radio", key: "dietaryRestrictions", labelName: "Dietary Restrictions", required: false,
            optionValues: [
                { id: 'yes', label: 'Yes', value: "Yes" },
                { id: 'no', label: 'No', value: "No" }
            ]
        },
        {
            type: "text", key: "dietaryNeeds", labelName: "Dietary Restrictions Needs", maxLength: 100,
            required: false, conditional: true, dependsOn: 'dietaryRestrictions', dependsValue: ["No"],
        },
        { type: "text", key: "emergencyFirstName", labelName: "Emergency First Name", required: false },
        { type: "text", key: "emergencyLastName", labelName: "Emergency Last Name", required: false },
        { type: "text", key: "relationship", labelName: "Relationship", required: false },
        { type: "tel", key: "emergencyPhone", labelName: "Emergency Phone", maxLength: 15, required: false },
        {
            type: "radio", key: "emergencyPhoneType", labelName: "Emergency Phone Type", required: false,
            optionValues: [
                { id: 'landline', value: "Landline", label: "Landline" },
                { id: 'mobile', value: "Mobile", label: "Mobile" }
            ]
        },
        { type: "text", key: "participateReason", labelName: "Participate Reason", required: false },
    ]


    const navigate = useNavigate();
    const dispatch = useDispatch();
    const currentUser = useSelector((state) => state.user.currentUser);

    useEffect(() => {
        setStartTime(new Date());
    }, []);

    const submit = async (formData) => {
        const submitDate = new Date();
        try {
            dispatch(createEACResponseParams({
                ...formData,
                companyId: currentUser.companyId,
                startTime: startTime.toLocaleDateString() + ' ' + startTime.toLocaleTimeString(),
                submittedTime: submitDate.toLocaleDateString() + ' ' + submitDate.toLocaleTimeString(),
                submittedBy: currentUser.firstName + ' ' + currentUser.lastName,
            }));

            navigate("/admin/microsoft-form");
        }
        catch (error) {
            toast(`${messages.error.prefix} ${error}.`, { type: "error", toastId: "add-eac-response-error" });
            navigate("/admin/microsoft-form");
        }
    }

    const cancel = () => {
        navigate("/admin/microsoft-form");
    }

    return (
        <div>
            <SharedForm array={arr} onSubmit={submit} onCancel={cancel} />
        </div>
    )
}

export default withAuth(AddEACParticipationResponse);
