import { USER_ROLE } from '../../constants';

export const menuData = [
    {
        label: 'Admin Center',
        Icon: 'admin-center',
        to: '/admin',
        parentLabel: 'Admin Center',
        roles: [USER_ROLE.SYSTEM_ADMIN, USER_ROLE.CLIENT_ADMIN],
        level: 0,
        children: [
            {
                label: 'Cohort Management',
                Icon: 'cohort-management',
                to: '/cohort-management',
                level: 1,
            },
            {
                label: 'Company Management',
                Icon: 'company-management',
                to: '/company-management',
                roles: [USER_ROLE.SYSTEM_ADMIN],
                level: 1,
            },
            {
                label: 'Content Management',
                Icon: 'content-management',
                to: '/content-management',
                level: 1,
            },
            {
                label: 'System Notifications',
                Icon: 'system-notifications',
                to: '/notifications',
                level: 1,
            },
            {
                label: 'User Management',
                Icon: 'user-management',
                to: '/user-management',
                lastElement: true,
                level: 1,
            },
        ],
    },
    {
        label: 'Home',
        Icon: 'home',
        to: '/',
        parentLabel: 'Home',
        level: 0,
    },
    {
        label: 'Quality Management',
        Icon: 'quality-management',
        to: '/quality-management',
        parentLabel: 'Quality Management',
        level: 0,
        children: [
            {
                label: 'Hospital',
                Icon: 'hospital',
                to: '/hospital',
                level: 1,
                children: [
                    {
                        label: 'Star Explorer',
                        Icon: 'star-explorer',
                        to: '/star-explorer',
                        level: 2,
                        children: [
                            {
                                label: 'Nation Dashboard',
                                Icon: 'nation-dashboard',
                                to: '/national-dashboard',
                                level: 3,
                            },
                            {
                                label: 'State Overview',
                                Icon: 'state-overview',
                                to: '/state-overview',
                                level: 3,
                                children: [
                                    {
                                        label: 'State Detail',
                                        Icon: 'state-detail',
                                        to: '/state-detail',
                                        level: 4,
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        label: 'Star Report Card',
                        Icon: 'star-report',
                        to: '/star-report',
                        level: 2,
                        children: [
                            {
                                label: 'Mortality',
                                Icon: 'mortality',
                                to: '/mortality',
                                level: 3,
                            },
                            {
                                label: 'Readmission',
                                Icon: 'readmission',
                                to: '/readmission',
                                level: 3,
                            },
                            {
                                label: 'Safety',
                                Icon: 'safety',
                                to: '/safety',
                                level: 3,
                            },
                            {
                                label: 'Patient Experience',
                                Icon: 'patient-experience',
                                to: '/patient-exp',
                                level: 3,
                            },
                            {
                                label: 'Timely',
                                Icon: 'timely',
                                to: '/timely',
                                level: 3,
                            },
                            {
                                label: 'Multi Facility',
                                Icon: 'multi-facility',
                                to: '/multi-facility',
                                level: 3,
                            },
                        ],
                    },
                    {
                        label: 'Star Planner',
                        Icon: 'star-planner',
                        to: '/star-planner',
                        level: 2,
                    },
                    {
                        label: 'PH Insights',
                        Icon: 'ph-insights',
                        to: '/ph-insights',
                        disabled: true,
                        level: 2,
                    },
                ],
            },
            {
                label: 'Payer',
                Icon: 'payer',
                to: '/payer',
                level: 1,
            },
            {
                label: 'SNF',
                Icon: 'snf',
                to: '/snf',
                lastElement: true,
                level: 1,
            },
        ],
    },
    {
        label: 'Member Analytics',
        Icon: 'member-analytics',
        to: '/member-analytics',
        parentLabel: 'Member Analytics',
        level: 0,
        children: [
            {
                label: 'Population Characterization',
                Icon: 'population-characterization',
                to: '/population-characterization',
                children: [
                    {
                        label: 'Core Demographics',
                        Icon: 'core-demographics',
                        to: '/core-demographics',
                        level: 2,
                    },
                    {
                        label: 'Financial',
                        Icon: 'financial',
                        to: '/financial-index',
                        level: 2,
                    },
                    {
                        label: 'Geographic Dispersion',
                        Icon: 'geographic-dispersion',
                        to: '/geographic-dispersion',
                        level: 2,
                    },
                    {
                        label: 'Health Access',
                        Icon: 'health-access',
                        to: '/health-access',
                        level: 2,
                    },
                    {
                        label: 'Technology',
                        Icon: 'technology',
                        to: '/technology',
                        level: 2,
                    },
                    {
                        label: 'Other',
                        Icon: 'other',
                        to: '/other-insights',
                        level: 2,
                    },
                    {
                        label: 'Compare Census Data',
                        Icon: 'compare-census-data',
                        to: '/compare-census',
                        level: 2,
                    },
                    {
                        label: 'Drilldown',
                        Icon: 'drilldown',
                        to: '/drilldown',
                        level: 2,
                        children: [
                            {
                                label: 'Geography Drill Down',
                                Icon: 'geographic-dispersion',
                                to: '/geographic-drill-down',
                                level: 3,
                            },
                            {
                                label: 'Health Access Drill Down',
                                Icon: 'health-access',
                                to: '/health-access-drill-down',
                                level: 3,
                            },
                            {
                                label: 'Technology Drill Down',
                                Icon: 'technology',
                                to: '/technology-drill-down',
                                level: 3,
                            },
                        ],
                    },
                ],
                level: 1,
            },
            {
                label: 'Retention Risk',
                Icon: 'retention-risk',
                to: '/retention-risk',
                children: [
                    {
                        label: 'View Risk Score',
                        Icon: 'view-risk-score',
                        to: '/risk-identification',
                        level: 2,
                    },
                    {
                        label: 'Monitor',
                        Icon: 'monitor',
                        to: '/risk-monitoring',
                        level: 2,
                        children: [
                            {
                                label: 'Enrollment',
                                Icon: 'enrollment',
                                to: '/enrollment',
                                disabled: true,
                                level: 3,
                            },
                            {
                                label: 'Alert Management',
                                Icon: 'alert-management',
                                to: '/alert-management',
                                level: 3,
                            },
                        ],
                    },
                    {
                        label: 'Explorer',
                        Icon: 'explorer',
                        to: '/risk-exploration',
                        disabled: true,
                        level: 2,
                        // children: [
                        //     {
                        //         label: 'Core Demographics',
                        //         Icon: '',
                        //         to: '/core-demographics',
                        //     },
                        //     {
                        //         label: 'Geographic Dispersion',
                        //         Icon: '',
                        //         to: '/geographic-dispersion',
                        //     },
                        //     {
                        //         label: 'Technology',
                        //         Icon: '',
                        //         to: '/technology',
                        //     },
                        //     {
                        //         label: 'Health Access',
                        //         Icon: '',
                        //         to: '/health-access',
                        //     },
                        //     {
                        //         label: 'Financial',
                        //         Icon: '',
                        //         to: '/financial-index',
                        //     },
                        //     {
                        //         label: 'Other',
                        //         Icon: '',
                        //         to: '/other-insights',
                        //     },
                        // ],
                    },
                ],
                level: 1,
            },
            {
                label: 'Health Equity',
                Icon: 'health-equity',
                to: '/health-equity',
                disabled: true,
                lastElement: true,
                level: 1,
            },
        ],
    },
    {
        label: 'Advisory Group Management',
        Icon: 'advisory-group-management',
        to: '/advisory-group-management',
        parentLabel: 'Advisory Group Management',
        level: 0,
        children: [
            {
                label: 'Analytics',
                Icon: 'analytics',
                to: '/analytics',
                children: [
                    {
                        label: 'Advisory Group Representatives',
                        Icon: 'advisory-group-representatives',
                        to: '/advisory-group-representatives',
                        level: 2,
                    },
                ],
                level: 1,
            },
            {
                label: 'Document Management',
                Icon: 'document-management',
                to: '/document-management',
                level: 1,
            },
            {
                label: 'Operations',
                Icon: 'operations',
                to: '/operations',
                level: 1,
                children: [
                    {
                        label: 'Meeting Notes',
                        Icon: 'meeting-notes',
                        to: '/meeting-notes',
                        level: 2,
                    },
                    {
                        label: 'Meeting Schedule',
                        Icon: 'meeting-schedule',
                        level: 2,
                        to: '/meeting-schedule',
                    },
                    {
                        label: 'Roster',
                        Icon: 'roster',
                        level: 2,
                        to: '/roster',
                    },
                ],
            },
            {
                label: 'Program Management',
                Icon: 'program-management',
                to: '/program-management',
                level: 1,
            },
            {
                label: 'Recruitment',
                Icon: 'recruitment',
                to: '/recruitment',
                level: 1,
                children: [
                    {
                        label: 'Application Entry',
                        Icon: 'application-entry',
                        to: '/application-entry',
                        level: 2,
                    },
                    {
                        label: 'Application Dashboard',
                        Icon: 'application-dashboard',
                        to: '/application-dashboard',
                        level: 2,
                    },
                    {
                        label: 'Panel Selection',
                        Icon: 'panel-selection',
                        to: '/panel-selection',
                        level: 2,
                    },
                ],
            },
            {
                label: 'Regulatory Reporting',
                Icon: 'regulatory-reporting',
                to: '/regulatory-reporting',
                disabled: true,
                lastElement: true,
                level: 1,
            },
        ]
    },
];

export default menuData;
