import React, { useEffect, useState } from 'react';
import { NavigationBar } from './NavigationBar';
import {
    AuthenticatedTemplate,
    UnauthenticatedTemplate,
} from '@azure/msal-react';
import { useLocation, Outlet, useNavigate } from "react-router-dom";
import SidebarNew from './SidebarNew';
import logo from "../../assets/images/logo-footer.png";
import styles from './Layout.module.scss';
import { useDispatch, useSelector } from "react-redux";
import HeaderBar from "./HeaderBar";
import { getPageSlug, getRolesBySlug } from "../../utils/utils";
import { setLocation } from "../../ducks/navigation";
import BreadCrumbs from "./BreadCrumbs";


function Layout() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const year = new Date().getFullYear();
    const location = useLocation();
    const pageLocation = useSelector((state) => state.location.currentLocation);
    const currentUser = useSelector((state) => state.user.currentUser);

    const [title, setTitle] = useState(pageLocation.page);
    const [accessCheck, setAccessCheck] = useState(false);

    const checkPageAccess = (slug) => {
        if(slug !== 'not-found' && slug !== ''){
            const roles = getRolesBySlug( `/${slug}`);
            if(currentUser && roles && !roles.includes(currentUser.roleId)) {
                navigate('not-found');
                dispatch(setLocation({ path: '/not-found', name: 'Home', page: 'Not Found' }));
            }
        }
        setAccessCheck(true);
    }

    useEffect(() => {
        setTitle(pageLocation.page);
        const pageSlug = getPageSlug(location.pathname);
        checkPageAccess(pageSlug);
    }, [location.pathname]);

    return (
        <>
            <AuthenticatedTemplate>
                <main className={styles.contentWrap}>
                    <SidebarNew />
                    {
                        accessCheck ?
                        <div className={`flex-grow-1 d-flex flex-column position-relative ${styles.content}`}>
                            <NavigationBar />
                            {title && <HeaderBar />}
                            <BreadCrumbs />
                            <div className={`flex-grow-1 d-flex flex-column ${styles.contentInner}`}>
                                <Outlet />
                            </div>
                            <footer className={`bg-white px-3 ${styles.footer}`}>
                                <div className="text-center text-muted">
                                    &copy; <strong>Predict Health, Inc</strong>{' '}
                                    {year}. All rights reserved.
                                </div>
                            </footer>
                        </div>
                        :
                        null
                    }
                </main>
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                <NavigationBar />
                <main className="flex-grow-1">
                    <Outlet />
                </main>
                <footer
                    className={`px-3 px-xl-4 pt-4 pt-xl-5 pb-3 pb-xl-4 d-md-flex justify-content-md-between`}>
                    <a
                        className="ps-xl-3"
                        href="https://predicthealth.ai"
                        target="_blank"
                        rel="noreferrer">
                        <img
                            src={logo}
                            alt="Predict Health"
                            className={styles.logo}
                        />
                    </a>
                    <div className="text-center text-muted pe-xl-3">
                        &copy; <strong>Predict Health, Inc</strong> {year}. All
                        rights reserved.
                    </div>
                </footer>
            </UnauthenticatedTemplate>
        </>
    );
}

export default Layout;
