import Modal from "react-bootstrap/Modal";
import React from "react";
import {Row} from "react-bootstrap";
import Loader from "./Loader";
import DataGrid from "../DataGrid";
import OverflowText from './OverflowTooltip'

export const ValidationSummaryModal = ({validationSummaryData, showModal, onHideCallback}) => {
    const columns = [
        {
            id: 'row',
            header: 'Row Number',
            accessorKey: 'row',
        },
        {
            id: 'column',
            header: 'Column',
            accessorKey: 'column',
        },
        {
            id: 'error',
            header: 'Error',
            accessorKey: 'error',
            cell: ({ row, cell }) => (<OverflowText id={cell.id}>{row.original.error}</OverflowText>),
        },
        {
            id: 'key',
            header: 'Key',
            accessorKey: 'key',
        },
        {
            id: 'value',
            header: 'Value',
            accessorKey: 'value',
        },
    ];

    return (
        <Modal show={showModal} fullscreen={false} onHide={onHideCallback} size="xl">
            <Modal.Header closeButton>
                <Modal.Title>Validation Summary</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row className="h-100">
                    {validationSummaryData === null ? <Loader className="d-flex align-items-center" animation="border" /> :
                        <DataGrid
                            columns={columns}
                            data={validationSummaryData}
                            enableFilters={false}
                        />}
                </Row>
            </Modal.Body>
        </Modal>
    );
};
