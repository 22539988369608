import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Alert, Modal, ProgressBar } from "react-bootstrap";
import { toast } from "react-toastify";
import { BsFillExclamationTriangleFill } from "react-icons/bs";
import { ALIGN, COHORT_TRAINING_FLAG, MIME_TYPES, POSITIONS } from "../../constants";
import SharedForm from "../../components/ui/Form";
import { checkWhiteSpace, isObjectEmpty, optionValues } from "../../utils/utils";
import api from "../../services/api/memberAnalyticsService";
import messages from "../../utils/helper/messages";
import styles from "../../styles/AddCohort.module.scss";

const NewCohortModal = ({ show, onCancel, onHideCallback }) => {
    const initialCohortState = { training: 'Y' };
    const currentUser = useSelector((state) => state.user.currentUser);

    const allowedFileTypes = [MIME_TYPES.XLS, MIME_TYPES.CSV];
    const trainingFlagOptions = optionValues(COHORT_TRAINING_FLAG);
    const [isLoading, setIsLoading] = useState(false);
    const [cohortDetails, setCohortDetails] = useState(initialCohortState);

    const [file, setFile] = useState("");
    const [progress, setProgress] = useState(0);
    const progressRef = useRef(null);

    const arr = [
        { key: 'name', labelName: 'Cohort Name', placeholderName: 'Cohort Name', type: 'text' },
        { key: 'training', labelName: 'Training Cohort', placeholderName: 'Training Cohort', type: 'options', optionValues: trainingFlagOptions },
        {
            key: 'file', labelName: 'Upload File', placeholderName: 'Upload File', type: 'file',
            allowedTypes: allowedFileTypes, fileNameKey: 'fileName'
        }
    ]

    const Submit = async () => {
        setIsLoading(true);

        const file = cohortDetails.file;

        let cohortName = cohortDetails.name;
        let fileObj = new File([file], `${currentUser.companyId}_${cohortName}_${file.name}`, { type: file.type });

        // set properties to assign to blob metadata
        fileObj.companyId = currentUser.companyId;
        fileObj.uploaderUserId = currentUser.id;
        fileObj.cohortName = cohortName;
        fileObj.originalFileName = file.name;
        fileObj.training = cohortDetails.training;
        console.log(fileObj);

        try {
            await api.uploadFile(fileObj, (event) => {
                progressRef.current = Math.round(
                    (100 * event.loaded) / event.total
                );
                setProgress(progressRef.current);
                if (progressRef.current === 100) {
                    toast(`${fileObj.name} ${messages.success.cohortUpload}`, { type: "success", toastId: `cohort-add-success` });
                    setIsLoading(false);
                    setCohortDetails(initialCohortState);
                    setProgress(0);
                    onCancel();
                }
            });
        } catch (error) {
            setIsLoading(false);
            setProgress(0);
            toast(`${messages.error.cohortUpload} ${fileObj.name} ${error}.`, { type: "error", toastId: `cohort-add-error` });
        }
    }

    const isValidCohortInfo = () => {
        if (isObjectEmpty(cohortDetails)) {
            return false;
        }

        const isEmptyString = cohortDetails?.name === '';
        const hasWhiteSpace = checkWhiteSpace(cohortDetails?.name);

        return !isEmptyString && !hasWhiteSpace
    }

    const handleCohortDetailsChange = (update) => {
        setCohortDetails((details) => ({ ...details, ...update }))
    }

    const inputChange = (name, value) => {
        if (name === "file") {
            handleCohortDetailsChange({ fileName: value.name, file: value });
            setFile(value);
        } else {
            handleCohortDetailsChange({ [name]: value });
        }
    }

    return (
        <Modal show={show} fullscreen={false} backdrop="static" onHide={onHideCallback} size="md">
            <Modal.Header closeButton>
                <Modal.Title>New Cohort</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="px-xl-3 py-xl-2">
                    <SharedForm
                        cols={12}
                        array={arr}
                        modelObj={cohortDetails}
                        onSubmit={Submit}
                        onCancel={onCancel}
                        onInputChanged={inputChange}
                        loading={isLoading}
                        disabled={!isValidCohortInfo()}
                        actionBarPosition={POSITIONS.TOP}
                        actionBarAlign={ALIGN.RIGHT}
                    />
                    {
                        progress > 0 &&
                        <div className="mt-2">
                            <span className={styles.fileName}>{file.name}</span>-
                            <span className={styles.fileSize}>{(file.size / 1000).toFixed(1)} KB</span>
                            <div className={styles.fileProgress} >
                                <ProgressBar now={progress} />
                            </div>
                        </div>
                    }
                </div>
                {
                    !isValidCohortInfo() &&
                    <Alert
                        variant="warning"
                        className="mt-2 py-2 lh-sm"
                    >
                        <div className="d-flex align-items-center">
                            <BsFillExclamationTriangleFill className="me-2" />
                            {messages.error.cohortNameValidation}
                        </div>
                    </Alert>
                }
            </Modal.Body>
        </Modal>
    )
}

export default NewCohortModal;
