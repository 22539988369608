import {Button, Col, Row, Form} from "react-bootstrap";
import {RiEditFill} from "react-icons/ri";
import {FaCog} from "react-icons/fa";
import withAuth from "../../../components/hoc/withAuth";
import DataGrid from "../../../components/DataGrid";
import ActionBar from "../../../components/ui/ActionBar";
import {useNavigate} from "react-router-dom";

// mock data, remove after backend integration
const validatedCohorts = [
    {
        name: 'Test1',
        status: 'Validated',
        fileName: 'test1',
        dateTime: '07/05/2023 5:32:12PM'
    },
    {
        name: 'Test2',
        status: 'Validated',
        fileName: 'test2',
        dateTime: '07/06/2023 5:32:12PM'
    }
];

const alertMessages = [
    {
        id: 1,
        memberId: 2,
        description: 'Lorem ipsum dolor',
        priority: 'Urgent',
    },
    {
        id: 2,
        memberId: 3,
        description: 'Lorem ipsum dolor',
        priority: 'High Priority',
    },
];

const RiskMonitoring = () => {
    const navigate = useNavigate();

    const cohortColumns = [
        {
            name: 'check',
            header: ' ',
            size: 36,
            minSize: 36,
            cell: ({ row }) => (
                <Form.Check
                    variant="secondary"
                    id={row.original.id}
                    size="sm"
                    className="me-1 mb-1 d-flex align-items-center"
                />
            )
        },
        {
            id: 'name',
            header: 'Cohort Name',
            size: 160,
            accessorKey: 'name',
        },
        {
            id: 'fileName',
            header: 'File Name',
            accessorKey: 'fileName',
        },
        {
            id: 'dateTime',
            header: 'Date Time',
            accessorKey: 'dateTime',
        }
    ]

    const alertColumns = [
        {
            name: 'check',
            header: ' ',
            size: 36,
            minSize: 36,
            cell: ({ row }) => (
                <Form.Check
                    variant="secondary"
                    id={row.original.id}
                    size="sm"
                    className="me-1 mb-1 d-flex align-items-center"
                />
            )
        },
        {
            name: 'description',
            header: 'Description',
            accessorKey: 'description',
            enableColumnFilter: false,
        },
        {
            name: 'priority',
            header: 'Priority',
            accessorKey: 'priority',
            size: 130,
        }
    ]

    const handleEnroll = () => {
        // handle enroll code
    }

    const handleManageAlerts = () => {
        // manage alerts code
        navigate('alert-management');
    }

    return (
        <div className="h-100 d-flex flex-column">
            <div className="flex-grow-1">
                <Row className="flex-grow-1">
                    <Col xl>
                        <ActionBar title="Cohorts">
                            <Button
                                type="submit"
                                className="flex-shrink-0"
                                variant="light"
                                onClick={handleEnroll}
                            >
                                <RiEditFill size={14} className="me-1" />Enroll
                            </Button>
                        </ActionBar>
                        <DataGrid
                            columns={cohortColumns}
                            data={validatedCohorts}
                            enableFilters={false}
                        />
                    </Col>
                    <Col xl className="mt-4 mt-xl-0">
                        <ActionBar title="Alerts">
                            <Button
                                type="submit"
                                className="flex-shrink-0"
                                variant="light"
                                onClick={handleManageAlerts}
                            >
                                <FaCog size={14} className="me-1" />Manage
                            </Button>
                        </ActionBar>
                        <DataGrid
                            columns={alertColumns}
                            data={alertMessages}
                        />
                    </Col>
                </Row>
            </div>
        </div>
    );
}

export default withAuth(RiskMonitoring);
