import {apiInstance} from "../apiInstance";
import {protectedResources} from "../../msalAuthConfig";

const headers = {
    'Content-Type': 'application/json',
};

const cohortApi = {
    getCohortData: (id) => {
        return apiInstance({
            method: 'GET',
            url: `${protectedResources.apiCompany.endpoint}/${id}/cohorts`,
            headers,
        })
    },

    getCohortDataByStatus: (id, status) => {
        return apiInstance({
            method: 'GET',
            url: `${protectedResources.apiCompany.endpoint}/${id}/cohorts/${status}`,
            headers,
        })
    },

    getCohortNotifications: (id) => {
        return apiInstance({
            method: 'GET',
            url: `${protectedResources.apiCohortManagement.endpoint}/${id}/notifications`,
            headers,
        })
    },

    mergeCohorts: async (cohorts) => {
        return await apiInstance({
            method: 'post',
            url: `${protectedResources.apiCohortManagement.endpoint}/merge`,
            headers: {
                "Content-Type": 'application/json'
            },
            data: cohorts,
        })
    },

    getCohortUploadItem: (cohortId) => {
        return apiInstance({
            method: 'GET',
            url: `${protectedResources.apiCohortManagement.endpoint}/${cohortId}/cohortUploadItem`,
            headers,
        })
    },

    archiveCohort: (cohortId) => {
        return apiInstance({
            method: 'PUT',
            url: `${protectedResources.apiCohortManagement.endpoint}/${cohortId}/archive`,
            headers,
        })
    },

    scheduleCohort: (cohortId) => {
        return apiInstance({
            method: 'PUT',
            url: `${protectedResources.apiCohortManagement.endpoint}/${cohortId}/schedule`,
            headers,
        })
    },

};

export default cohortApi;
