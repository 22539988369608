import React from "react";
import withAuth from '../../components/hoc/withAuth';
import Placeholder from '../../components/ui/Placeholder';

const Operations = () => {
    return (
        <Placeholder title='Operations' />
    );
};

export default withAuth(Operations);
