import React, { useEffect, useContext, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from "react-router-dom";
import { AppLoadContext } from '../../components/ui/AppLoadContext'
import DataGrid from "../../components/DataGrid";
import withAuth from "../../components/hoc/withAuth";
import Loader from "../../components/ui/Loader";
import api from '../../services/api/cohortService';
import { toDateTime } from "../../utils/utils";
import { ValidationSummaryModal } from "../../components/ui/ValidationSummaryModal";


export const CohortUploadOverview = () => {
    const dispatch = useDispatch();
    const { cohortId } = useParams();
    const isAppLoaded = useContext(AppLoadContext);
    const currentUser = useSelector((state) => state.user.currentUser);

    const [cohortUploadItem, setCohortUploadItem] = useState(null);
    const [showValidationSummaryModal, setValidationSummaryModal] = useState(false);
    const [validationSummaryData, setValidationSummaryData] = useState(null);

    const defaultSort = [{
        id: 'processedOn',
        desc: true
    }];

    useEffect(() => {
        async function fetchData() {
            if (isAppLoaded && cohortId) {
                let result = await api.getCohortUploadItem(cohortId);
                if (result.error == null) {
                    setCohortUploadItem(result.data);
                }
            }
        }

        fetchData();

    }, [dispatch, isAppLoaded, currentUser]);

    const renderValidationSummaryModal = (validationArray) => {
        setValidationSummaryModal(true);
        setValidationSummaryData(validationArray);
    }

    const renderValidationSummary = (row) => {
        let validationSummary = row.validationSummary;

        try {
            let validationArray = JSON.parse(validationSummary);
            if (Array.isArray(validationArray) && validationArray.length > 0) {
                return (<div className="text-decoration-underline text-secondary cursor-pointer" onClick={() => { renderValidationSummaryModal(validationArray) }}>View Validation Failures</div>)
            } else {
                return (<div>{validationSummary}</div>)
            }
        } catch {

            return (<div>{validationSummary}</div>)
        }
    }

    const columns = [
        {
            id: 'cohortName',
            header: 'Cohort Name',
            accessorKey: 'cohortName',
        },
        {
            id: 'uploaderName',
            header: 'Uploader Name',
            accessorFn: row => `${row.firstName} ${row.lastName}`,
        },
        {
            id: 'status',
            header: 'Cohort status',
            accessorKey: 'status',
        },
        {
            id: 'validationSummary',
            header: 'Validation Summary',
            accessorKey: 'validationSummary',
            cell: ({ row }) => (
                <>
                    {renderValidationSummary(row.original)}
                </>

            )
        },
        {
            id: 'processedOn',
            header: 'Processed On',
            accessorKey: 'processedOn',
            sortType: 'date',
            accessorFn: row => `${toDateTime(row.processedOn)}`,
        },
    ];

    return (
        <>
            <div className="h-100">
                {cohortUploadItem === null ? <Loader className="d-flex align-items-center" animation="border" /> :
                    <DataGrid
                        columns={columns}
                        sort={defaultSort}
                        data={cohortUploadItem}
                        enableFilters={false}
                    />}
            </div>
            <ValidationSummaryModal
                showModal={showValidationSummaryModal}
                validationSummaryData={validationSummaryData}
                onHideCallback={() => {
                    setValidationSummaryModal(false);
                    setValidationSummaryData(null);
                }} />
        </>
    );
}

export default withAuth(CohortUploadOverview);
