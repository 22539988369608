import { PhoneNumberUtil } from 'google-libphonenumber';
import { MIME_TYPES, USER_ROLE } from '../constants';
import menuData from '../components/ui/menuData';

const moment = require('moment');

export function parsePhoneNumber(phoneNumber) {
    if (isDevMode()) {
        return phoneNumber;
    }

    const phoneUtil = PhoneNumberUtil.getInstance();

    let number = phoneUtil.parse(phoneNumber, 'US');
    let nationalNumber = number.getNationalNumber();

    return nationalNumber;
}

export function formatPhoneNumber(phoneNumber) {
    // e.g., 5551234567 -> 555-123-4567
    try {
        let phoneNumberWithDashes = phoneNumber.replace(
            /(\d)(\d)(\d)(\d)(\d)(\d)(\d)(\d)(\d)(\d)/,
            '$1$2$3-$4$5$6-$7$8$9$10'
        );
        return phoneNumberWithDashes;
    } catch {
        return phoneNumber;
    }
}

export function isPhoneNumberValid(
    phoneNumber,
    useStrictPhoneValidation = true
) {
    if (isDevMode() || useStrictPhoneValidation === false) {
        if (phoneNumber) {
            // strip all chars except numbers
            let cleanedNumber = phoneNumber.replace(/[^\d]/g, '');
            return cleanedNumber.length === 10;
        } else {
            return false;
        }
    }

    let valid = false;
    try {
        const phoneUtil = PhoneNumberUtil.getInstance();
        valid = phoneUtil.isValidNumberForRegion(
            phoneUtil.parse(phoneNumber.toString(), 'US'),
            'US'
        );
    } catch (e) {
        valid = false;
    }

    return valid;
}

export function isEmailValid(email) {
    let valid = false;
    try {
        const regularExpression = RegExp(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
        valid = regularExpression.test(email);
    } catch (e) {
        valid = false;
    }

    return valid;
}

export function isObjectEmpty(obj) {
    if (!obj) {
        return true;
    }
    return !Object.keys(obj).length;
}

export function isArrayUniqueValues(arr) {
    return new Set(arr).size === arr.length;
}

export function isDevMode() {
    return process.env.NODE_ENV === 'development';
}

export function isUserAdmin(user) {
    return (
        user?.roleId === USER_ROLE.SYSTEM_ADMIN ||
        user?.roleId === USER_ROLE.CLIENT_ADMIN
    );
}

export function isUserSysAdmin(user) {
    return user?.roleId === USER_ROLE.SYSTEM_ADMIN;
}

export function isDevTestUserId(userId) {
    if (!userId) {
        return false;
    }

    return userId < 0;
}

export function toDateTime(dateString) {
    return dateString ? moment(dateString).format('MM/DD/YYYY HH:mm:ss') : '';
}

export function toDate(dateString) {
    return dateString ? moment(dateString).format('MM/DD/yyyy') : '';
}

export function toDateWithMonth(dateString) {
    return dateString ? moment(dateString).format('MMMM, DD yyyy') : '';
}

export function checkWhiteSpace(val) {
    return /^\s+|\s{2,}|\s+$/.test(val);
}

export function optionValues(obj) {
    let optionArr = [];
    for (const [key, value] of Object.entries(obj)) {
        optionArr.push({ id: key, value: value });
    }
    return optionArr;
}

export function getPageSlug(url) {
    if (!url) return;
    const pathArr = url.split('/');
    return pathArr[pathArr.length - 1];
}

export function getRolesBySlug(
    slug,
    data = menuData,
    defaultRoles = Object.values(USER_ROLE)
) {
    for (let item of data) {
        const parentRoles = item.roles || defaultRoles;
        if (item.to === slug) {
            return item.roles || parentRoles;
        } else if (item.children) {
            const itemRoles = getRolesBySlug(slug, item.children, item.roles);
            if (itemRoles?.length > 0) {
                return itemRoles;
            }
        }
    }
    return null;
}

export function getFileExtension(fileName) {
    return fileName?.split('.').pop();
}

export function getFileContentType(fileName) {
    if (!fileName) return;
    const fileExt = getFileExtension(fileName);
    return MIME_TYPES[fileExt.toUpperCase()];
}
