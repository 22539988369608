import { useState } from "react";
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { Card, Col, Row } from 'react-bootstrap';
import Blog from './Blog';
import Login from "./Login";
import styles from './Home.module.scss';
import Icon from "../components/ui/Icon";

// Images
import graph from './../assets/images/qm-correlation-image.jpg';
import externalViewpoint from './../assets/images/hp-stars-screenshot.jpg';

// PDFs
import externalViewpointPDF from './../assets/docs/StarsLeadersBenchmarking_EarlyView_Webinar_PH_RWC_9_22_2022.pdf';
import PdfModal from "../components/ui/PdfModal";

const Home = () => {
    const [showModal, setShowModal] = useState(false);

    return (
        <>
            <UnauthenticatedTemplate>
                <Login />
            </UnauthenticatedTemplate>

            <AuthenticatedTemplate>
                <>
                    <div className="d-flex flex-column h-100 pt-4">
                        <Row className="flex-grow-1 g-xl-5">
                            <Col md={6} className="px-3 ps-5">
                                <div className={"pb-3 h-50"}>
                                    <Card className={`border-1 h-100 ${styles.box} ${styles.borderDarkSalmon}`}>
                                        <Card.Body>
                                            <h3 className={`${styles.sectionHeader}`}><Icon icon="graph-of-the-month" size={22} className={`text-muted me-2 ${styles.colorDarkSalmon}`} /><strong>Graph of the Month</strong></h3>
                                            <Row>
                                                <Col md={6}>
                                                    <Card.Text className={`${styles.graphText, styles.cardText} mt-3`}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et
                                                        dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</Card.Text>
                                                    </Col>
                                                    <Col md={6}>
                                                    <div className={`${styles.imgWrapper} ${styles.graphImgWrapper}`}>
                                                        <img src={graph} alt="Graph of the Month"/>
                                                    </div>
                                                    </Col>
                                            </Row>
                                        </Card.Body>
                                    </Card>
                                </div>
                                <div className="pt-3 h-50">
                                    <Card className={`border-1 h-100 ${styles.box} ${styles.borderBlue}`}>
                                        <Card.Body>
                                        <h3 className={`${styles.sectionHeader}`}><Icon icon="external-viewport" size={22} className={`text-muted me-2 ${styles.colorBlue}`} /><strong>External Viewpoint</strong></h3>
                                            <Row>
                                                <Col md={4}>
                                                    <Card.Text className={`${styles.cardText} mt-3`}>Rex Wallace Consulting and Predict Health recently completed a survey of health plans
                                                        asking about their practices as it relates to the CMS Stars program.</Card.Text>
                                                </Col>
                                                <Col md={8}>
                                                    <div className={`${styles.imgWrapper} cursor-pointer`} onClick={() => setShowModal(true)}>
                                                        <img src={externalViewpoint} alt="External ViewPoint"/>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Card.Body>
                                    </Card>
                                </div>
                            </Col>
                            <Col md={6} className="pe-5">
                                <Blog customStyle={`border-1 h-100 ${styles.box} ${styles.borderBlack}`} />
                            </Col>
                        </Row>
                    </div>
                    <div className={`flex-grow-1 mt-5 mx-n3 mb-n3 ${styles.section}`}>
                        <h3 className={`${styles.sectionHeaderLarge}`}><strong>Product Updates</strong></h3>
                        <Card.Text className={`${styles.cardText}`}>Product Updates Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.</Card.Text>
                    </div>

                    { showModal &&
                        <PdfModal
                            title="External Viewpoint"
                            file={externalViewpointPDF}
                            open={showModal}
                            modalStyle={styles.pdfModal}
                            modalClose={() => setShowModal(false)}
                        />
                    }
                </>
            </AuthenticatedTemplate>
        </>
  );
}

export default Home;
