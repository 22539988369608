import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Button } from "react-bootstrap";
import { setLocation } from "../ducks/navigation";

function NotFound() {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const goToHome = () => {
        navigate('/');
        dispatch(setLocation({ path: '/', name: 'Home', page: 'Home' }));
    }

    return (
        <div className="d-flex justify-content-center align-items-center h-100">
            <div className="text-center p-3 p-md-5">
                <h1 className="display-2 fw-bold">404</h1>
                <div className="h5">Page Not Found</div>
                <Button
                    variant="primary"
                    className="mt-5"
                    onClick={goToHome}>
                    Go to Dashboard
                </Button>
            </div>
        </div>
    );
}

export default NotFound;
