import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import LoadingButton from "./LoadingButton";
import {
    isPhoneNumberValid,
    parsePhoneNumber,
    isEmailValid,
    getFileExtension
} from "../../utils/utils";
import { BsFillFilePdfFill } from "react-icons/bs";
import { PiFileHtmlFill } from "react-icons/pi";
import { FaImage } from "react-icons/fa";
import { ALIGN, MIME_TYPES, POSITIONS } from "../../constants";
import styles from './Form.module.scss';

function SharedForm({
    array,
    modelObj,
    onSubmit,
    onCancel,
    onInputChanged,
    cols = 4,
    useStrictPhoneValidation = true,
    loading,
    disabled = false,
    actionBarPosition = POSITIONS.BOTTOM,
    actionBarAlign = ALIGN.LEFT,
    submitButtonText = 'Submit',
    cancelButtonText = 'Cancel',
}) {

    const [validated, setValidated] = useState(false);
    const [imgName, setImg] = useState('');
    const [imgNameSrc, setImgSrc] = useState('');
    const [formData, setFormData] = useState(null);
    const [errors, setErrors] = useState({});
    const defaultFileTypes = [MIME_TYPES.PNG, MIME_TYPES.JPG, MIME_TYPES.GIF];

    useEffect(() => {
        setImgSrc('');
        setFormDataIfNeeded();
    }, [modelObj]);

    const setFormDataIfNeeded = () => {
        if (!modelObj) {
            modelObj = {};
            for (let item of array) {
                // if option, select the first item in the array as the default value
                if (item.type === 'options') {
                    modelObj[item.key] = item.optionValues[0].id;
                } else {
                    modelObj[item.key] = '';
                }
            }
        }

        for (let item of array) {
            if (item.type === 'file') {
                if (imgName === '' && modelObj['fileURL']) {
                    if (modelObj['fileURL'].imageSrc) {
                        setImgSrc(`${modelObj['fileURL'].imageSrc}`);
                    } else {
                        setImgSrc(`${modelObj['fileURL']}`);
                    }
                }
            }
        }
        setFormData(modelObj);
    };

    const validateFormData = (formData) => {
        for (let item of array) {
            if (item.type === 'phone' && item.key in formData && !isPhoneNumberValid(formData[item.key], useStrictPhoneValidation)) {
                setErrors({ ...errors, [item.key]: { key: true, value: "It looks like the number entered isn't registered as a valid phone number." } });
                return false;
            }
            if (item.type === 'email' && item.key in formData && !isEmailValid(formData[item.key])) {
                setErrors({ ...errors, [item.key]: { key: true, value: "Please enter a valid email address" } });
                return false;
            }
        }

        return true;
    }

    const transformFormDataIfNeeded = (formData) => {
        for (let item of array) {
            if (item.type === 'phone' && item.key in formData) {
                formData[item.key] = parsePhoneNumber(formData[item.key]);
            }
        }
    };

    const FileName = ({ icon, name }) => {
        const Icon = icon;
        return (
            <div className={styles.fileWrap} title={name}>
                <span className={styles.iconWrap}>
                    <Icon className="text-muted" />
                </span>
                <span className={`${styles.fileName} text-truncate`}>
                    {name}
                </span>
            </div>
        );
    };

    const FileTypeDisplay = ({ item }) => {
        const fileNameKey = item.fileNameKey ?? item.key;
        const fileName = typeof formData[fileNameKey] === "object" ? formData[fileNameKey].name : formData[fileNameKey];
        const fileExt = getFileExtension(fileName);

        switch (fileExt) {
            case 'pdf':
                return (
                    <>
                        <FileName name={fileName} icon={BsFillFilePdfFill} />
                        <object
                            data={imgNameSrc}
                            type={MIME_TYPES.PDF}
                            className="w-100 border border-1 bg-light rounded mt-2"
                        />
                    </>
                );
            case 'html':
                return (
                    <>
                        <FileName name={fileName} icon={PiFileHtmlFill} />
                        <object
                            data={imgNameSrc}
                            type={MIME_TYPES.HTML}
                            className="w-100 border border-1 rounded mt-2"
                        />
                    </>
                );
            case 'jpg':
            case 'png':
            case 'gif':
            case 'svg':
                return (
                <>
                    <FileName name={fileName} icon={FaImage} />
                    {imgNameSrc !== '' && (
                        <div className={`${styles.imageWrap} w-100 border border-1 bg-light rounded mt-2`}>
                            <img src={imgNameSrc} alt={formData[item.key]} />
                        </div>
                    )}
                </>
            );
            default:
                return null;
        }
    }

    const handleSubmit = (event) => {
        const form = event.currentTarget;

        event.preventDefault();
        event.stopPropagation();

        if (form.checkValidity() === false) {
            setValidated(true);
        } else {
            const formSubmitData = new FormData(event.currentTarget);
            const formSubmitDataObj = Object.fromEntries(formSubmitData.entries());

            if (!validateFormData(formSubmitDataObj)) {
                setValidated(false);
                return;
            }

            transformFormDataIfNeeded(formSubmitDataObj);

            if (onSubmit) {
                onSubmit(formSubmitDataObj);
            }
        }
    };

    const inputChanged = e => {
        let name = e.target.name;
        let value = e.target.value;

        if (e.target.files) {
            const selectedFile = e.target.files[0];
            let allowedTypes = e.target.accept.split(',');
            setImg(value);
            if (allowedTypes.some((type) => type === selectedFile.type)) {
                const MIN_FILE_SIZE = 1 // 1KB
                const MAX_FILE_SIZE = 5120 // 5MB
                const fileSizeKiloBytes = selectedFile.size / 1024
                if (fileSizeKiloBytes < MIN_FILE_SIZE) {
                    setErrors({ ...errors, [e.target.name]: { key: true, value: "File size is less than minimum limit" } });
                    setValidated(false);
                    return
                }
                if (fileSizeKiloBytes > MAX_FILE_SIZE) {
                    setErrors({ ...errors, [e.target.name]: { key: true, value: "File size is greater than maximum limit" } });
                    setValidated(false);
                    return
                }
            }
            else {
                setErrors({ ...errors, [e.target.name]: { key: true, value: `This format is not allowed, please upload allowed file types only` } });
                setValidated(false);
                return;
            }
            let reader = new FileReader();
            reader.onload = function () {
                if (onInputChanged) {
                    onInputChanged(name, selectedFile);
                }
                setImgSrc(reader.result);
                setFormData({ ...formData, [name]: selectedFile });
            };
            reader.readAsDataURL(e.target.files[0]);
        } else {
            if (onInputChanged) {
                onInputChanged(name, value);
            }

            setFormData({ ...formData, [name]: value });
        }

        // remove and update if needed
        delete errors[name];
        setErrors({ ...errors });
    };

    // default require is true for all.
    const isRequired = (item) => {
        return (!((item.type === "file" && imgNameSrc !== '') || (item.required === false)))
    }

    const renderFormControl = (modelObj, item) => {
        if (item.type === 'options') {
            return (
                <>
                    <Form.Select
                        disabled={item.disabled}
                        name={item.key}
                        value={formData[item.key]}
                        onChange={inputChanged}>
                        {item?.optionValues?.map((optionInfo) => {
                            return (
                                <option
                                    key={optionInfo.id}
                                    value={optionInfo.id}>
                                    {optionInfo.value}
                                </option>
                            );
                        })}
                    </Form.Select>
                </>
            );
        } else if (item.type === 'radio') {
            let logoBgColor = formData[item.key]
                ? formData[item.key]
                : formData.fileURL?.metaData?.logobgcolor;
            return (
                <>
                    {item?.optionValues?.map((option) => {
                        return (
                            <Form.Check
                                inline
                                type={item.type}
                                name={item.key}
                                key={option.id}
                                label={option.label}
                                id={`${item.key}-${option.id}`}
                                value={`${option.value}`}
                                onChange={inputChanged}
                                checked={option.value === logoBgColor}
                            />
                        );
                    })}
                </>
            );
        } else if (item.type === 'file') {
            let allowedFileTypes =
                item.allowedTypes?.join(',') ?? defaultFileTypes.join(',');
            let extensionsArr = Object.keys(MIME_TYPES).filter((format) =>
                allowedFileTypes
                    .split(',')
                    .some((mime) => mime === MIME_TYPES[format])
            );
            let helperText = extensionsArr.join(', ');

            return (
                <>
                    <Form.Control
                        type={item.type}
                        accept={allowedFileTypes}
                        placeholder={item.placeholderName}
                        required={isRequired(item)}
                        value={imgName}
                        onChange={inputChanged}
                        name={item.key}
                        isInvalid={
                            errors[item.key]?.key &&
                            errors[item.key].key === true
                        }
                    />
                    {imgNameSrc !== '' && <FileTypeDisplay item={item} />}
                    <Form.Text
                        muted
                        className="d-block w-100">
                        Allowed file types: {helperText}
                    </Form.Text>
                    <Form.Control.Feedback type="invalid">
                        {errors[item.key]?.value
                            ? errors[item.key].value
                            : `Please choose a ${item.labelName}.`}
                    </Form.Control.Feedback>
                </>
            );
        } else {
            return (
                <>
                    <Form.Control
                        type={item.type}
                        placeholder={item.placeholderName}
                        required={isRequired(item)}
                        value={formData[item.key]}
                        onChange={inputChanged}
                        name={item.key}
                        isInvalid={
                            errors[item.key]?.key &&
                            errors[item.key].key === true
                        }
                    />
                    <Form.Control.Feedback type="invalid">
                        {errors[item.key]?.value
                            ? errors[item.key].value
                            : `Please choose a ${item.labelName}.`}
                    </Form.Control.Feedback>
                </>
            );
        }
    };

    const FormActionsBar = () => (
        <div className={`text-${actionBarAlign} ${actionBarPosition === POSITIONS.BOTTOM ? 'mt-4' : 'mb-4'}`}>
            <Button variant="outline-primary" type="cancel" className="me-2" onClick={onCancel}>{cancelButtonText}</Button>
            <LoadingButton type="submit" loading={loading} disabled={loading || disabled}>{submitButtonText}</LoadingButton>
        </div>
    )

    // wait for form data before rendering to be set to prevent warning
    if (!formData) {
        return null;
    }

    return (
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
            {
                actionBarPosition === POSITIONS.TOP &&
                <FormActionsBar />
            }
            {array.map((obj) => {
                return <Row className="mb-3" key={obj.key}>
                    <Form.Group as={Col} md={cols} controlId={`validationCustom${obj.key}`}
                        style={{
                            display: obj.conditional && (formData[obj.dependsOn] === "" || obj.dependsValue.includes(formData[obj.dependsOn])) ? 'none' : null
                        }}
                    >
                        <Form.Label>{obj.labelName}</Form.Label>
                        <InputGroup hasValidation>
                            {renderFormControl(formData, obj)}
                        </InputGroup>
                    </Form.Group>
                </Row>
            })
            }
            {
                actionBarPosition === POSITIONS.BOTTOM &&
                    <FormActionsBar />
            }
        </Form>
    );
}

export default SharedForm;
