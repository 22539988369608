import {useState} from "react";
import {Badge, Card} from "react-bootstrap";
import PdfModal from "../components/ui/PdfModal";
import styles from "./Blog.module.scss";
import blogData from "./blogData";

const Blog = (props) => {
    const [showModal, setShowModal] = useState(false);
    const [file, setFile] = useState(null);
    const [title, setTitle] = useState('');
    const customStyle = props.customStyle;

    const BlogItem = (props) => {

        const openFile = (file, title) => {
            setTitle(title);
            setFile(file);
            setShowModal(true);
        }

        return (
            <div>
                <div className={styles.blogAudience}>
                    { props.audience.map(aud => <Badge key={aud} className={`me-2 ${styles.autoWidth} ${styles.colorLightBlue}`}>{aud}</Badge>) }
                </div>
                <div>
                    <h4 className={`mb-1 ${styles.blogTitle}`} onClick={() => openFile(props.file, props.title)}>
                        <span>{props.title}</span>
                    </h4>
                </div>
                <div className={`text-primary ${styles.autoWidth}`}>
                    {props.date}
                </div>
            </div>
        );
    }

    return (
        <>
            <Card className={`${customStyle}`}>
                <Card.Body className="p-0">
                    <h3 className={`text-center ${styles.blogHeader} mb-0`}><strong>Predict Health Blog</strong></h3>
                    <div className="p-3">
                        {
                            blogData.map(item =>
                                <Card className={`be-1 pt-1 pb-1 ps-3 pe-3 ${styles.blogCard}`} key={`blog-${item.id}`}>
                                    <Card.Body className={`${styles.borderBottom}`}>
                                        <BlogItem
                                            title={item.title}
                                            date={item.date}
                                            audience={item.audience}
                                            file={item.file}
                                        />
                                    </Card.Body>
                                </Card>)
                        }
                    </div>
                </Card.Body>
            </Card>

            { showModal &&
                <PdfModal
                    title={title}
                    file={file}
                    open={showModal}
                    modalStyle={styles.pdfModal}
                    modalClose={() => setShowModal(false)}
                />
            }
        </>
    );
}

export default Blog;
