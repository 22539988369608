import { protectedResources } from '../../msalAuthConfig';
import { useMsal } from '@azure/msal-react';
import React, { useEffect, useState } from 'react';
import { Alert, Modal } from 'react-bootstrap';
import { BsFillExclamationTriangleFill } from 'react-icons/bs';
import SharedForm from '../../components/ui/Form';
import { USER_ROLE, ALIGN, POSITIONS } from '../../constants';
import { getUsers, getCurrentUser } from '../../ducks/user';
import { useDispatch, useSelector } from 'react-redux';
import { acquireAccessToken } from '../../services/api/auth';
import addInterceptors from '../../utils/interceptors';
import createInstance from '../../utils/instance';
import { isUserSysAdmin, isObjectEmpty } from '../../utils/utils';
import { getCompany } from '../../ducks/company';
import Loader from '../../components/ui/Loader';
import withAuth from '../../components/hoc/withAuth';
import { toast } from 'react-toastify';
import messages from '../../utils/helper/messages';

export const ApiInstance = addInterceptors(createInstance());

const AddUserModal = ({ show, onCancel }) => {
    const dispatch = useDispatch();
    const { instance } = useMsal();

    const currentUser = useSelector((state) => state.user.currentUser);
    const companies = useSelector((state) => state.company.companies);

    const [formData, setFormData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isButtonLoading, setIsButtonLoading] = useState(false);
    const [userDetails, setUserDetails] = useState(null);

    useEffect(() => {
        async function fetchData() {
            setIsLoading(true);

            await dispatch(getCurrentUser(instance));
        }

        fetchData();
    }, [dispatch, instance]);

    useEffect(() => {
        async function fetchData() {
            if (!currentUser) {
                return;
            }

            // grab all companies, if system admin
            if (currentUser?.roleId === USER_ROLE.SYSTEM_ADMIN) {
                await dispatch(getCompany());
            }
        }

        fetchData();
    }, [dispatch, currentUser]);

    useEffect(() => {
        if (
            !currentUser ||
            (currentUser?.roleId === USER_ROLE.SYSTEM_ADMIN && !companies)
        ) {
            return;
        }

        let companyOptions =
            currentUser?.roleId === USER_ROLE.SYSTEM_ADMIN
                ? companies.map((company) => {
                      return { id: company.id, value: company.name };
                  })
                : [
                      {
                          id: currentUser.companyId,
                          value: currentUser.companyName,
                      },
                  ];

        let roleOptions = [
            { id: USER_ROLE.USER, value: 'User' },
            { id: USER_ROLE.CLIENT_ADMIN, value: 'Client Admin' },
            { id: USER_ROLE.SYSTEM_ADMIN, value: 'System Admin' },
        ];

        setFormData([
            {
                key: 'firstName',
                labelName: 'First Name',
                placeholderName: 'First Name',
                type: 'text',
            },
            {
                key: 'lastName',
                labelName: 'Last Name',
                placeholderName: 'Last Name',
                type: 'text',
            },
            {
                key: 'email',
                labelName: 'Email',
                placeholderName: 'Email',
                type: 'email',
            },
            {
                key: 'phone',
                labelName: 'Phone Number',
                placeholderName: 'Phone Number',
                type: 'phone',
            },
            {
                key: 'roleId',
                labelName: 'User Role',
                type: 'options',
                optionValues: isUserSysAdmin(currentUser)
                    ? roleOptions
                    : roleOptions.filter(
                          (option) => option.id !== USER_ROLE.SYSTEM_ADMIN
                      ),
            },
            {
                key: 'companyId',
                labelName: 'Company Name',
                type: 'options',
                optionValues: companyOptions,
            },
        ]);

        setIsLoading(false);
    }, [companies, currentUser]);

    const isValidUserInfo = () => {
        if (isObjectEmpty(userDetails)) {
            return false;
        }

        return true;
    };

    const submitCallback = async (formData) => {
        setIsLoading(true);
        setIsButtonLoading(true);

        try {
            let accessToken = await acquireAccessToken(instance);
            ApiInstance.setAuth(accessToken);

            const user = await ApiInstance({
                method: 'POST',
                url: protectedResources.apiUsers.endpoint,
                data: {
                    email: formData.email,
                    roleId: formData.roleId,
                    companyId: formData.companyId,
                    firstName: formData.firstName,
                    lastName: formData.lastName,
                    phone: formData.phone,
                },
            });

            if (user.data) {
                await ApiInstance({
                    method: 'POST',
                    url: protectedResources.apiUserInvite.endpoint,
                    data: {
                        inviteUserId: user.data.id,
                    },
                });

                setIsButtonLoading(false);
                toast(`${messages.success.createUser}`, {
                    type: 'success',
                    toastId: 'add-user-success',
                });
                setUserDetails(null);
                onCancel();
                dispatch(getUsers(instance));
            }
        } catch (error) {
            setIsButtonLoading(false);
            toast(`${messages.error.prefix} ${error}.`, {
                type: 'error',
                toastId: 'add-user-error',
            });
        }

        setIsLoading(false);
    };

    const cancelCallback = () => {
        onCancel();
    };

    const handleUserDetailsChange = (update) => {
        setUserDetails((details) => ({ ...details, ...update }));
    };

    const inputChange = (name, value) => {
        handleUserDetailsChange({ [name]: value });
    };

    return (
        <>
            <Modal
                show={show}
                fullscreen={false}
                backdrop="static"
                onHide={onCancel}
                size="md">
                <Modal.Header closeButton>
                    <Modal.Title>New User</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="px-xl-3 py-xl-2">
                        <SharedForm
                            cols={12}
                            array={formData}
                            modelObj={userDetails}
                            onSubmit={submitCallback}
                            onCancel={cancelCallback}
                            onInputChanged={inputChange}
                            loading={isButtonLoading}
                            disabled={!isValidUserInfo()}
                            actionBarPosition={POSITIONS.TOP}
                            actionBarAlign={ALIGN.RIGHT}
                        />
                        {isLoading && <Loader />}
                    </div>
                    {userDetails && !isValidUserInfo() && (
                        <Alert
                            variant="warning"
                            className="mt-2 py-2 lh-sm">
                            <div className="d-flex align-items-center">
                                <BsFillExclamationTriangleFill className="me-2" />
                                {messages.error.userDetailsValidation}
                            </div>
                        </Alert>
                    )}
                </Modal.Body>
            </Modal>
        </>
    );
};

export default withAuth(AddUserModal);
