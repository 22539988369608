import {NavLink, useNavigate} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux'
import SidebarItemHeader from "./SidebarItemHeader";
import styles from "./SidebarItem.module.scss";
import {setLocation} from "../../ducks/navigation";
import Icon from "../ui/Icon";

const SidebarItem = ({ item }) => {

    const { label, Icon: fontIcon, to, children, parentLabel, disabled, roles, level } = item;
    const activeStyle = `${styles.navItem} ${styles.activeNavItem}`;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const currentUser = useSelector((state) => state.user.currentUser);
    const paddedValue = level ? (level * 15) + 12 : 12;

    if(children) {
        return <SidebarItemHeader item={item}/>
    }

    if(roles && !roles.includes(currentUser?.roleId)) {
        return <></>
    }

    return (
        <NavLink
            to={to}
            className={({isActive}) =>
                isActive ? activeStyle : styles.navItem
            }
            onClick={(e) => {
                e.preventDefault();
                if(!disabled){
                    dispatch(setLocation({path: to, name: parentLabel, page: label}))
                    navigate(to);
                }
            }}
            style={disabled ? { opacity: 0.5, cursor: 'default', paddingLeft: `${paddedValue}px` } : { paddingLeft: `${paddedValue}px` }}
        >
            <Icon icon={fontIcon} size={18} className="me-2" />
            <span className={styles.navLabel}>{label}</span>
        </NavLink>
    );
}

export default SidebarItem;
