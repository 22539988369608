import React from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import styles from './NavigationBox.module.scss';
import { setLocation } from '../../ducks/navigation';
import Icon from './Icon';

function NavigationBox(props) {
    const { path, locationName, pageName, icon, children, classname } =
        props;
    const navigate = useNavigate();
    const dispatch = useDispatch();

    return (
        <NavLink
            to={path}
            className={`${styles.navigationBox} ${styles[classname]}  d-flex flex-column rounded-3 mb-3`}
            onClick={(e) => {
                e.preventDefault();
                dispatch(
                    setLocation({
                        path: path,
                        name: locationName,
                        page: pageName,
                    })
                );
                navigate(path);
            }}>
            <div className="d-flex flex-row">
                <Icon
                    icon={icon}
                    size={32}
                />
                <h4 className={`${styles.pageLinkText} fw-bold mb-0 ms-3`}>
                    {pageName}
                </h4>
            </div>
            <label className={`${styles.navigationDesc}`}>{children}</label>
        </NavLink>
    );
}

export default NavigationBox;
