import React from 'react';
import styles from '../../views/member-analytics/member-management/MemberManagementHome.module.scss';
import NavigationBox from './NavigationBox';
import { Nav } from 'react-bootstrap';

export const NavigationBoxDashboard = ({ data }) => {
    return (
        <Nav className={`${styles.pageLinks}  flex-column`}>
            {data && data.map((navBoxItem) => {
                return <NavigationBox
                    key={navBoxItem.path}
                    path={navBoxItem.path}
                    locationName={navBoxItem.locationName}
                    pageName={navBoxItem.pageName}
                    icon={navBoxItem.icon}
                    classname={navBoxItem.classname}>
                    {navBoxItem.description}
                </NavigationBox>
            })}
        </Nav>
    );
};

export default NavigationBoxDashboard;
