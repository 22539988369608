import React, { useEffect, useState, useContext } from 'react';
import { useSelector } from 'react-redux';
import { AppLoadContext } from '../../../components/ui/AppLoadContext';
import withAuth from '../../../components/hoc/withAuth';
import PopulationPowerBi from './PopulationPowerBi';

const CoreDemographics = () => {

    const [reportConfig, setReportConfig] = useState(null);

    const report = useSelector((state) => state.report);

    const isAppLoaded = useContext(AppLoadContext);

    useEffect(() => {
        if (isAppLoaded && report.coreDemographic.embedId)
            setReportConfig({
                ...report.coreDemographic,
                accessToken: report.accessToken,
            });
    }, [report, isAppLoaded]);

    return (
        <PopulationPowerBi
            reportConfig={reportConfig}
            title="Core Demographics"
        />
    );
};

export default withAuth(CoreDemographics);
