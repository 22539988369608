import React from "react";
import withAuth from '../../components/hoc/withAuth';
import Placeholder from '../../components/ui/Placeholder';

const Recruitment = () => {
    return (
        <Placeholder title='Recruitment' />
    );
};

export default withAuth(Recruitment);
