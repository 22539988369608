import withAuth from '../../../components/hoc/withAuth';
import PopulationPowerBi from './PopulationPowerBi';
import React, {useContext, useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {AppLoadContext} from "../../../components/ui/AppLoadContext";

const TechnologyLiteracy = () => {
    const [reportConfig, setReportConfig] = useState(null);

    const report = useSelector((state) => state.report);

    const isAppLoaded = useContext(AppLoadContext);

    useEffect(() => {
        if (isAppLoaded && report.technology.embedId)
            setReportConfig({
                ...report.technology,
                accessToken: report.accessToken,
            });
    }, [report, isAppLoaded]);

    return (
        <PopulationPowerBi
            reportConfig={reportConfig}
            title="Technology Literacy"
        />
    );
};

export default withAuth(TechnologyLiteracy);
