import { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { IoMdArrowRoundBack } from 'react-icons/io';
import { setLocation } from '../../../ducks/navigation';
import ActionBar from '../../../components/ui/ActionBar';

const PopulationActionbar = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [homePath, setHomePath] = useState('');

    const getParentPath = (path) => {
        const pathArr = path.split('/');
        pathArr.pop();
        return pathArr.join('/');
    };

    useEffect(() => {
        let parentPath = getParentPath(location.pathname);
        setHomePath(parentPath);
    }, [location.pathname]);

    return (
        <ActionBar back={true}>
            <Button
                type="button"
                variant="light"
                className="me-2"
                onClick={() => {
                    dispatch(
                        setLocation({
                            path: homePath,
                            name: 'Member Analytics',
                            page: 'Population Characterization',
                        })
                    );
                    navigate(homePath);
                }}>
                <IoMdArrowRoundBack className="me-1" />
                Back
            </Button>
        </ActionBar>
    );
};

export default PopulationActionbar;
