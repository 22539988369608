import { Col, Nav } from 'react-bootstrap';
import { RiMapPinUserFill } from 'react-icons/ri';
import { NavLink, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { BiChip } from 'react-icons/bi';
import { MdHealthAndSafety } from 'react-icons/md';
import { setLocation } from '../../../../ducks/navigation';
import withAuth from "../../../../components/hoc/withAuth";
import styles from "../../../admin/Admin.module.scss";

const PopulationDrillDown = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const Section = ({ page, path, icon }) => {
        const Icon = icon;

        return (
            <NavLink
                to={path}
                className={`${styles.pageLink} flex-grow-1 d-flex flex-column align-items-center justify-content-center rounded-2 py-5 px-3 mb-4`}
                onClick={(e) => {
                    e.preventDefault();
                    dispatch(
                        setLocation({
                            path: `/member-analytics/population-characterization/drilldown/${path}}`,
                            name: 'Member Analytics',
                            page,
                        })
                    );
                    navigate(path);
                }}>
                <Icon className={`${styles.pageLinkIcon} mb-2`} />
                <h4 className={`${styles.pageLinkText} fw-bold mb-0 ms-3`}>
                    {page}
                </h4>
            </NavLink>
        );
    };

    return (
        <div className="h-100 d-flex flex-column">
            <div className="flex-grow-1">
                <Nav className={`${styles.pageLinks} row p-0`}>
                    <Col md={4}>
                        <Section
                            path="geographic-drill-down"
                            page="Geograpy Drill Down"
                            icon={RiMapPinUserFill}
                        />
                    </Col>
                    <Col md={4}>
                        <Section
                            path="technology-drill-down"
                            page="Technology Drill Down"
                            icon={BiChip}
                        />
                    </Col>
                    <Col md={4}>
                        <Section
                            path="health-access-drill-down"
                            page="Health Access Drill Down"
                            icon={MdHealthAndSafety}
                        />
                    </Col>
                </Nav>
            </div>
        </div>
    )
}

export default withAuth(PopulationDrillDown);
