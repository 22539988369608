const pageString = {
    navigation: {
        home: 'Home',
        adminCenter: 'Admin Center',
        advisoryGroupManagement: 'Advisory Group Management',
        marketAssessment: 'Market Assessment',
        memberAnalytics: 'Member Analytics',
        planDesign: 'Plan Design and Fit',
        qualityManagement: 'Quality Management',
        eacParticipationResponsesResponse: 'EAC Participation Responses',
    },
    route: {
        home: '',
        adminCenter: 'admin',
        advisoryGroupManagement: 'advisory-group-management',
        planDesign: 'plan-design',
        qualityManagement: 'quality-management',
        marketAssessment: 'market-assessment',
        memberAnalytics: 'member-analytics',
        eacParticipationResponsesResponse: 'eac',
    },
    routePatterns: [
        'admin',
        'admin/*',
        '',
        'advisory-group-management',
        'market-assessment',
        'plan-design',
        'quality-management',
        'quality-management/*',
        'member-analytics',
        'member-analytics/*',
    ]
};

export default pageString;
