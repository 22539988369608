import {useNavigate} from "react-router-dom";
import {Button, Form} from "react-bootstrap";
import {FaSearch} from "react-icons/fa";
import {MdMonitor} from "react-icons/md";
import withAuth from "../../../components/hoc/withAuth";
import DataGrid from "../../../components/DataGrid";
import ActionBar from "../../../components/ui/ActionBar";

// mock data, remove after backend integration
const validatedCohorts = [
    {
        name: 'Test1',
        status: 'Validated',
        fileName: 'test1',
        dateTime: '07/05/2023 5:32:12PM'
    },
    {
        name: 'Test2',
        status: 'Validated',
        fileName: 'test2',
        dateTime: '07/06/2023 5:32:12PM'
    }
];

const processingMessages = [
    {
        id: 1,
        message: 'Lorem ipsum dolor sit amet',
        dateTime: '07/05/2023 5:32:12PM'
    },
    {
        id: 2,
        message: 'Lorem ipsum dolor sit amet asd fasdfa asdf',
        dateTime: '07/06/2023 5:32:12PM'
    }
];

const RetentionRisk = () => {
    const navigate = useNavigate();

    const cohortColumns = [
        {
            name: 'check',
            header: ' ',
            size: 36,
            minSize: 36,
            cell: ({ row }) => (
                <Form.Check
                    variant="secondary"
                    id={row.original.id}
                    size="sm"
                    className="me-1 mb-1 d-flex align-items-center"
                />
            )
        },
        {
            id: 'name',
            header: 'Cohort Name',
            accessorKey: 'name',
        },
        {
            id: 'fileName',
            header: 'File Name',
            accessorKey: 'fileName',
        },
        {
            id: 'dateTime',
            header: 'Date Time',
            accessorKey: 'dateTime',
            size: 160,
        }
    ];

    const messageColumns = [
        {
            id: 'dateTime',
            header: 'Date Time',
            accessorKey: 'dateTime',
            size: 160,
        },
        {
            id: 'message',
            header: 'Message',
            accessorKey: 'message'
        }
    ];

    const handleViewRiskReport = () => {
        navigate('risk-identification');
    }

    const handleRiskMonitor = () => {
        navigate('risk-monitoring');
    }

    return (
        <div className="h-100 d-flex flex-column">
            <div className="flex-grow-1 mb-3">
                <ActionBar title="Cohorts">
                    <div>
                        <Button
                            type="submit"
                            className="flex-shrink-0 me-2"
                            variant="light"
                            onClick={handleViewRiskReport}
                        >
                            <FaSearch size={14} className="me-1" />Risk Report
                        </Button>
                        <Button
                            type="submit"
                            className="flex-shrink-0"
                            variant="light"
                            onClick={handleRiskMonitor}
                        >
                            <MdMonitor size={14} className="me-1" />Risk Monitor
                        </Button>
                    </div>
                </ActionBar>
                <DataGrid
                    columns={cohortColumns}
                    data={validatedCohorts}
                    enableFilters={false}
                />
            </div>
            <div className="flex-grow-1">
                <ActionBar title="Processing Messages"></ActionBar>
                <DataGrid
                    columns={messageColumns}
                    data={processingMessages}
                    enableFilters={false}
                />
            </div>
        </div>
    )
}

export default withAuth(RetentionRisk);
