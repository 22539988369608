import React, { useState } from 'react';
import Modal from "react-bootstrap/Modal";
import SharedForm from '../../../components/ui/Form';
import Form from "react-bootstrap/Form";

const EditMemberModal = ({ loading, show, member, onConfirm, onCancel, onHideCallback }) => {
    const [excludeMember, setExcludeMember] = useState(false);

    const arr = [
        {
            key: 'firstName',
            labelName: 'Name', type: 'text'
        },
        {
            key: 'lastName',
            labelName: 'Last Name', type: 'text'
        },
        {
            key: 'addressLine1',
            labelName: 'Address', type: 'text'
        },
        { key: 'state', labelName: 'State', placeholderName: 'State', type: 'text' },
        { key: 'postalCode', labelName: 'Portal Code', placeholderName: 'Portal Code', type: 'text' },
        {
            key: 'birthday',
            labelName: 'Birthday',
            type: 'date',
        },
        {
            key: 'email',
            labelName: 'Email',
            type: 'email',
        },
        {
            key: 'phone',
            labelName: 'Phone',
            type: 'phone',
        },
        {
            key: 'enrollDate',
            labelName: 'Enroll Date',
            type: 'date',
        },
        {
            key: 'terminationDate',
            labelName: 'Termination Date',
            type: 'date',
        },
        {
            key: 'contract',
            labelName: 'Contract',
            type: 'text',
        },
        {
            key: 'pbpId',
            labelName: 'PBP ID',
            type: 'text',
        },
        {
            key: 'salesChannelName',
            labelName: 'Sales Channel Name',
            type: 'text',
        },
        {
            key: 'writingAgentId',
            labelName: 'Agent Id',
            type: 'text',
        },
        {
            key: 'writingAgentName',
            labelName: 'Agent Name',
            type: 'text',
        },
        {
            key: 'customerInteractionsCountPhone',
            labelName: 'Interactions Count Phone',
            type: 'int',
        },
        {
            key: 'customerInteractionsCountEmail',
            labelName: 'InteractionsCountEmail',
            type: 'int',
        },
        {
            key: 'customerInteractionsCountWeb',
            labelName: 'Interactions Count Web',
            type: 'int',
        },
        {
            key: 'customerInteractionsCountBroker',
            labelName: 'Interactions Count Broker',
            type: 'int',
        },
        {
            key: 'customerInteractionsCountOther',
            labelName: 'Interactions Count Other',
            type: 'int',
        }
    ];

    const onSave = async (formData) => {
        setExcludeMember(false);
        onConfirm({ ...formData, status: excludeMember === true ? 'R' : member.status.trim() })
    }

    return (
        <Modal show={show} fullscreen={false} backdrop="static" onHide={onHideCallback} size="md">
            <Modal.Header closeButton>
                <Modal.Title>Member Data</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Check variant="secondary" size="sm" label="Exclude from cohort" className="mb-3" checked={excludeMember} onChange={() => { setExcludeMember((s) => (!s)); }}
                />
                <SharedForm array={arr} modelObj={member} onSubmit={onSave} onCancel={onCancel} cols={12} useStrictPhoneValidation={false} loading={loading} />
            </Modal.Body>
        </Modal>
    )
}

export default EditMemberModal;
