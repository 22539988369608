import React, { useContext, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { getEacParticipationResponses } from '../../ducks/eacParticipationResponses'
import { AppLoadContext } from "../../components/ui/AppLoadContext";
import DataGrid from "../../components/DataGrid";
import Loader from "../../components/ui/Loader";
import withAuth from "../../components/hoc/withAuth";
import { formatPhoneNumber } from "../../utils/utils";
import ActionBar from "../../components/ui/ActionBar";
import { Button } from "react-bootstrap";
import { FaPlus } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

function EacParticipationResponses() {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const isAppLoaded = useContext(AppLoadContext);
    const eacParticipationResponses = useSelector((state) => state.eacParticipationResponses.responses);
    const currentUser = useSelector((state) => state.user.currentUser);

    const columns = [
        {
            id: 'startTime',
            header: 'Start time',
            accessorKey: 'startTime',
        },
        {
            id: 'submittedTime',
            header: 'SubmitttedDtm',
            accessorKey: 'submittedTime',
        },
        {
            id: 'email',
            header: 'Email',
            accessorKey: 'email',
        },
        {
            id: 'submittedBy',
            header: 'SubmittedBy',
            accessorKey: 'submittedBy',
        },
        {
            id: 'language',
            header: 'Language',
            accessorKey: 'primaryLanguage',
        },
        {
            id: 'participateReason',
            header: 'Participate Reason',
            accessorKey: 'participateReason',
        },
        {
            id: 'needTranslator',
            header: 'Will you need a translator?',
            accessorKey: 'needTranslator',
        },
        {
            id: 'needTransportation',
            header: 'Will you need transportation?',
            accessorKey: 'needTransportation',
        },
        {
            id: 'fullName',
            header: 'Contact Person',
            accessorFn: row => `${row.firstName} ${row.lastName}`,
        }, {
            id: 'address',
            header: 'Address',
            accessorKey: 'address',
            enableSorting: false,
        },
        {
            id: 'city',
            header: 'City',
            accessorKey: 'city',
        },
        {
            id: 'state',
            header: 'State',
            accessorKey: 'state',
        },
        {
            id: 'postalCode',
            header: 'Portal',
            accessorKey: 'postalCode',
        },
        {
            id: 'phone',
            header: 'Phone',
            accessorKey: 'phone',
            cell: ({ row }) => (formatPhoneNumber(row.original.phone))
        },
        {
            id: 'phoneType',
            header: 'Phone Type',
            accessorKey: 'phoneType',
        },
        {
            id: 'memberId',
            header: 'MemberID',
            accessorKey: 'memberId',
        },
        {
            id: 'internet',
            header: 'Internet',
            accessorKey: 'internet',
        },
        {
            id: 'accommodation',
            header: 'Accommodation',
            accessorKey: 'accommodation',
        },
        {
            id: 'accommodationNeeds',
            header: 'Accommodation Needs',
            accessorKey: 'accommodationNeeds',
        },
        {
            id: 'dietaryRestrictions',
            header: 'Dietary Restrictions',
            accessorKey: 'dietaryRestrictions',
        },
        {
            id: 'dietaryNeeds',
            header: 'Dietary Restrictions Needs',
            accessorKey: 'dietaryNeeds',
        },
        {
            id: 'emergencyFullName',
            header: 'Emergency Contact Person',
            accessorFn: row => `${row.emergencyFirstName} ${row.emergencyLastName}`,
        },
        {
            id: 'relationship',
            header: 'Relationship',
            accessorKey: 'relationship',
        },
        {
            id: 'emergencyPhone',
            header: 'Emergency Phone',
            accessorKey: 'emergencyPhone',
        },
        {
            id: 'emergencyPhoneType',
            header: 'Emergency Phone Type',
            accessorKey: 'emergencyPhoneType',
        },

    ];

    useEffect(() => {
        if (isAppLoaded) {
            if (!currentUser) {
                return;
            }
            dispatch(getEacParticipationResponses(currentUser?.companyId))
        }
    }, [dispatch, isAppLoaded, currentUser]);

    return (
        <>
            <ActionBar>
                {
                    <Button
                        onClick={() => navigate(`/admin/eac-participation-response`)}
                        variant="primary"
                    >
                        <FaPlus size={14} className="me-1" />Add EAC Participation Response
                    </Button>
                }
            </ActionBar>
            {eacParticipationResponses === null ? <Loader /> :
                <DataGrid
                    columns={columns}
                    data={eacParticipationResponses}
                    enableFilters={false}
                />}
        </>
    );
}

export default withAuth(EacParticipationResponses);
