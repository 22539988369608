import React, { useState, useEffect, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toDateWithMonth } from '../../utils/utils';
import styles from '../../views/member-analytics/member-management/MemberManagementHome.module.scss';
import ContentFileModal from './ContentFileModal';
import { CMS_CONTENT_FORMAT, MIME_TYPES } from '../../constants';
import { AppLoadContext } from './AppLoadContext';
import { getContent } from '../../ducks/content';

export const ContentDashboard = () => {
    const dispatch = useDispatch();
    const [blogContent, setBlogContent] = useState(null);
    const [showFileModal, setShowFileModal] = useState(false);
    const isAppLoaded = useContext(AppLoadContext);

    const contentList = useSelector((state) => state.content.contents);
    useEffect(() => {
        if (isAppLoaded) {
            dispatch(getContent());
        }
    }, [dispatch, isAppLoaded]);

    const handleClick = (blog) => {
        const { id, title, format } = blog;
        const type = MIME_TYPES[CMS_CONTENT_FORMAT[format]];
        const content = { id, title, type };
        setBlogContent(content);
        setShowFileModal(true);
    };

    const modalClose = () => {
        setShowFileModal(false);
    };


    return (
        <div>
            <div
                className={`${styles.analyUpdtBox} d-flex flex-column`}>
                {contentList &&
                    contentList.map((blog) => {
                        return (
                            <div
                                key={blog.id}
                                className={`${styles.updatesItem} d-flex flex-column`}>
                                <div
                                    className={`${styles.itemTitle} text-secondary cursor-pointer`}
                                    onClick={() =>
                                        handleClick(blog)
                                    }>
                                    {blog.title}
                                </div>
                                <label>
                                    {toDateWithMonth(
                                        blog.createdDateTime
                                    )}
                                </label>
                            </div>
                        );
                    })}
            </div>

            {blogContent && <ContentFileModal
                show={showFileModal}
                contentId={blogContent.id}
                title={blogContent.title}
                contentType={blogContent.type}
                className={styles.viewContent}
                modalClose={() => modalClose()}
            />}
        </div>
    );
};

export default ContentDashboard;
